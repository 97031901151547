<div>
  <mat-dialog-content>
    <p>{{ "purchase-order-overview.update-dialog" | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="mat-raised-button mat-primary" (click)="confirmUpdate()">
      {{ "purchase-order-overview.proceed" | translate }}
    </button>
  </mat-dialog-actions>
</div>
