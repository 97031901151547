import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../services/login.service";
export class AuthGuard {
    constructor(router, loginService) {
        this.router = router;
        this.loginService = loginService;
    }
    canActivate(route, state) {
        const currentUser = this.loginService.currentUser;
        console.log('currentUser', currentUser);
        if (currentUser) {
            return true;
        }
        this.router.navigate(['']);
        return false;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.LoginService)); }, token: AuthGuard, providedIn: "root" });
