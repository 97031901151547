/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./final-check-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/dialog";
import * as i5 from "./final-check-dialog.component";
var styles_FinalCheckDialogComponent = [i0.styles];
var RenderType_FinalCheckDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FinalCheckDialogComponent, data: {} });
export { RenderType_FinalCheckDialogComponent as RenderType_FinalCheckDialogComponent };
function View_FinalCheckDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("final-check-dialog.title")); _ck(_v, 1, 0, currVal_0); }); }
function View_FinalCheckDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("final-check-dialog.confirm-title")); _ck(_v, 1, 0, currVal_0); }); }
function View_FinalCheckDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "mat-raised-button mat-primary mar-25 "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.proceed(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("final-check-dialog.complete")); _ck(_v, 1, 0, currVal_0); }); }
function View_FinalCheckDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "mat-raised-button mat-primary mar-25 "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.proceed(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("final-check-dialog.add-accessory-unit")); _ck(_v, 1, 0, currVal_0); }); }
function View_FinalCheckDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "mat-raised-button mat-primary mar-25 "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.proceed(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("final-check-dialog.confirm-button")); _ck(_v, 1, 0, currVal_0); }); }
export function View_FinalCheckDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinalCheckDialogComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinalCheckDialogComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 7, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinalCheckDialogComponent_3)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinalCheckDialogComponent_4)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinalCheckDialogComponent_5)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showAccessoryButton; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showAccessoryButton; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.showAccessoryButton; _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.showAccessoryButton; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.showAccessoryButton; _ck(_v, 12, 0, currVal_4); }, null); }
export function View_FinalCheckDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-final-check-dialog", [], null, null, null, View_FinalCheckDialogComponent_0, RenderType_FinalCheckDialogComponent)), i1.ɵdid(1, 245760, null, 0, i5.FinalCheckDialogComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FinalCheckDialogComponentNgFactory = i1.ɵccf("app-final-check-dialog", i5.FinalCheckDialogComponent, View_FinalCheckDialogComponent_Host_0, {}, {}, []);
export { FinalCheckDialogComponentNgFactory as FinalCheckDialogComponentNgFactory };
