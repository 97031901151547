/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./copy-unit-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../add-remove-input/add-remove-input.component.ngfactory";
import * as i5 from "../add-remove-input/add-remove-input.component";
import * as i6 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./copy-unit-dialog.component";
import * as i11 from "../../services/suppliers.service";
var styles_CopyUnitDialogComponent = [i0.styles];
var RenderType_CopyUnitDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CopyUnitDialogComponent, data: {} });
export { RenderType_CopyUnitDialogComponent as RenderType_CopyUnitDialogComponent };
export function View_CopyUnitDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "dialog-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 21, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpod(3, { currentNve: 0 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "mat-dialog-content", [["class", "content-input mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i3.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-add-remove-input", [], null, [[null, "changeValue"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changeValue" === en)) {
        var pd_0 = (_co.changeValueFromInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_AddRemoveInputComponent_0, i4.RenderType_AddRemoveInputComponent)), i1.ɵdid(13, 114688, null, 0, i5.AddRemoveInputComponent, [i2.TranslateService], { value: [0, "value"], placeholder: [1, "placeholder"], maxNumber: [2, "maxNumber"] }, { changeValue: "changeValue" }), (_l()(), i1.ɵeld(14, 0, null, null, 8, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(15, 16384, null, 0, i3.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "mat-raised-button mat-primary mar-25"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyUnit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 3, "button", [["class", "mat-raised-button mat-primary mar-25"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelCopy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(20, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(21, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.unitQuantity; var currVal_4 = "copy-unit-dialog.unit-qty"; var currVal_5 = _co.maxUnitsAllowed; _ck(_v, 13, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform("copy-unit-dialog.title", _ck(_v, 3, 0, _co.data.currentNve))); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("copy-unit-dialog.next-nve")); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.nextNVE; _ck(_v, 9, 0, currVal_2); var currVal_6 = (_co.unitQuantity === 0); _ck(_v, 16, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("copy-unit-dialog.proceed")); _ck(_v, 17, 0, currVal_7); var currVal_8 = (i1.ɵnov(_v, 20).disabled || null); var currVal_9 = (i1.ɵnov(_v, 20)._animationMode === "NoopAnimations"); _ck(_v, 19, 0, currVal_8, currVal_9); var currVal_10 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform("copy-unit-dialog.cancel")); _ck(_v, 21, 0, currVal_10); }); }
export function View_CopyUnitDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-copy-unit-dialog", [], null, null, null, View_CopyUnitDialogComponent_0, RenderType_CopyUnitDialogComponent)), i1.ɵdid(1, 245760, null, 0, i10.CopyUnitDialogComponent, [i11.SuppliersService, i3.MatDialogRef, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CopyUnitDialogComponentNgFactory = i1.ɵccf("app-copy-unit-dialog", i10.CopyUnitDialogComponent, View_CopyUnitDialogComponent_Host_0, {}, {}, []);
export { CopyUnitDialogComponentNgFactory as CopyUnitDialogComponentNgFactory };
