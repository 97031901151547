import { Injectable, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  comp = {
    small: false,
    big: false,
    shippinglist: false
  };

  private printerListSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  printerList$ = this.printerListSubject.asObservable();



  isOpen = false;

  @Output() changes: EventEmitter<boolean> = new EventEmitter();

  print(data) {
    setTimeout(() => {
      console.log('### printer emiting the changes ###')
      this.changes.emit(data);
    });
  }

}
