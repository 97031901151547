import {Injectable} from '@angular/core';
import {
  articleLabel,
  contentLabelBody,
  contentLabelFooter,
  contentLabelHeader,
  hellmanContentLabelHeader,
  hellmanUnitLabelHeader,
  smallLabel,
  testBigLabel,
  testSmallLabel,
  unitLabelBody,
  unitLabelFooter,
  unitLabelHeader,
  powerSystemContentLabelHeader,
  powerSystemUnitLabelHeader
} from '../../assets/label/labels';
import {
  zplTestBigLabel600dpi,
  zplTestBigLabel300dpi,
  zplTestBigLabel203dpi
} from '../../assets/label/zplLabels';
import {
  zplBDUnitLabelHeader600dpi,
  zplBDUnitLabelHeader300dpi,
  zplBDUnitLabelHeader203dpi,
  zplHellmanUnitLabelHeader600dpi,
  zplHellmanUnitLabelHeader300dpi,
  zplHellmanUnitLabelHeader203dpi,
  zplUnitLabelBody600dpi,
  zplUnitLabelBody300dpi,
  zplUnitLabelBody203dpi,
  zplBDUnitLabelFooter600dpi,
  zplBDUnitLabelFooter300dpi,
  zplBDUnitLabelFooter203dpi,
  zplHellmanUnitLabelFooter600dpi,
  zplHellmanUnitLabelFooter300dpi,
  zplHellmanUnitLabelFooter203dpi,
  zplBDPowerSystemUnitLabelHeader203dpi,
  zplBDPowerSystemUnitLabelHeader300dpi,
  zplBDPowerSystemUnitLabelHeader600dpi
} from '../../assets/label/zplUnitLabels';
import {
  zplBDContentLabelHeader600dpi,
  zplBDContentLabelHeader300dpi,
  zplBDContentLabelHeader203dpi,
  zplHellmanContentLabelHeader600dpi,
  zplHellmanContentLabelHeader300dpi,
  zplHellmanContentLabelHeader203dpi,
  zplContentLabelBody600dpi,
  zplContentLabelBody300dpi,
  zplContentLabelBody203dpi,
  zplBDContentLabelFooter600dpi,
  zplBDContentLabelFooter300dpi,
  zplBDContentLabelFooter203dpi,
  zplHellmanContentLabelFooter600dpi,
  zplHellmanContentLabelFooter300dpi,
  zplHellmanContentLabelFooter203dpi,
  zplBDPowerSystemContentLabelFooter203dpi,
  zplBDPowerSystemContentLabelFooter300dpi,
  zplBDPowerSystemContentLabelFooter600dpi
} from '../../assets/label/zplContentLabels';
import {
  zplArticleLabel600dpi,
  zplArticleLabel300dpi,
  zplArticleLabel203dpi,
  zplBarcodeLabel600dpi,
  zplBarcodeLabel300dpi,
  zplBarcodeLabel203dpi,
  zplTestSmallLabel600dpi,
  zplTestSmallLabel300dpi,
  zplTestSmallLabel203dpi
} from '../../assets/label/zplSmallLabels';
import {Nve} from '../classes/Nve';
import {environment} from 'src/environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Printer} from './printer-config-wizard-service';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BehaviorSubject} from "rxjs";
import {companyContentLabelHeaders, companyUnitLabelHeaders} from "../../assets/label/labelsHeaders";

declare var JSPM: any;


@Injectable({
  providedIn: 'root'
})
export class PrintLabelService {
  printer: any;
  mySmallPrinter;
  myBigPrinter;
  isPrinterStart = false;
  licenseUrl = '';

  constructor(private translate: TranslateService
              ) {
  }

  initializePrinter() {

    this.mySmallPrinter = JSON.parse(localStorage.getItem('mySmallPrinter'));
    this.myBigPrinter = JSON.parse(localStorage.getItem('myBigPrinter'));
    console.log('initializePrinter', this.myBigPrinter, this.mySmallPrinter);

    if (this.mySmallPrinter !== null) {
      if (!this.isPrinterStart) {
        if (environment.licenseenabled) {
          JSPM.JSPrintManager.license_url = `${environment.sdaSupplierApiHost}/api/suppliers/supplier/jspmlicense`;
          console.log('JSPM.JSPrintManager.license_url', `${environment.sdaSupplierApiHost}/api/suppliers/supplier/jspmlicense`);
        }
        JSPM.JSPrintManager.cache_license_at_start = true;
        console.log('enable cache_license_at_start');
        JSPM.JSPrintManager.auto_reconnect = true;
        this.isPrinterStart = true;
        return true;
      }
      console.log('printerLocal setup');
    }
    console.log("returning init print false");
    return false;
  }

  reset() {
    if (this.isPrinterStart) {
      this.isPrinterStart = false;
      JSPM.JSPrintManager.auto_reconnect = false;
      JSPM.JSPrintManager.stop();
      console.log(".. stop normal print service");

    }
  }

  initializePrinterTest(printer: Printer) {

    // this.licenseUrl = JSON.parse(localStorage.getItem('licenseUrl'));
    console.log('initializePrinterTest', printer);


    if (printer !== null) {
      if (this.isPrinterStart) {
        this.isPrinterStart = false;
        JSPM.JSPrintManager.auto_reconnect = false;
        JSPM.JSPrintManager.stop();
        console.log(".. stop normal print service");

      }
      if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
        console.log(".. web socket already open");
        JSPM.JSPrintManager.stop();
        console.log(".. stop running test print service");

      }


    }
  }


  // Check for printer status
  jspmWSStatus() {
    console.log('JSPM.JSPrintManager.websocket_status', JSPM.JSPrintManager.websocket_status);
    console.log('JSPM.WSStatus.Open', JSPM.WSStatus.Open);
    console.log('JSPM.WSStatus.Closed', JSPM.WSStatus.Closed);
    console.log('JSPM.WSStatus.BlackListed', JSPM.WSStatus.BlackListed);

    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      return true;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
      console.log('JSPrintManager (JSPM) closed!');
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.BlackListed) {
      alert('JSPM has blacklisted this website!');
    }
    return false;
  }

  // Print NVE Barcodes


  printNVEBarcode(labels) {

    if (this.initializePrinter()) {
      JSPM.JSPrintManager.start(true, this.mySmallPrinter.host, this.mySmallPrinter.port).then((started: any) => {
        console.log('print service started ... do print');
        this.checkingIfWSisOpenAndStartPrinting('NveBarcode', labels);
      });
    } else {
      this.checkingIfWSisOpenAndStartPrinting('NveBarcode', labels);

    }
  }

  doPrintNVEBarcode(labels) {
    const printer = this.mySmallPrinter;
    console.log('labels in doPrintNVEBarcode: ', labels);
    console.log('attempt printNVEBarcode printer', printer);
    if (printer) {
      const printerLanguage = printer.printerLanguage;
      const printerResolution = printer.printerResolution;
      console.log('in doPrintNVEBarcode() printerLanguage is: ', printerLanguage);
      console.log('in doPrintNVEBarcode() printerResolution is: ', printerResolution);
      const printerName = printer.name;
      if (navigator.onLine) {

        console.log('on line print');


        if (this.jspmWSStatus()) {
          // Create a ClientPrintJob
          const cpj = new JSPM.ClientPrintJob();
          cpj.clientPrinter = new JSPM.InstalledPrinter(printerName);
          // Set Printer type (Refer to the help, there many of them!)
          console.log(cpj);
          if (printerLanguage === 'IPL') {
            const label = smallLabel;
            for (let index = 0; index < labels.length; index++) {
              const labelCS = this.addCheckSum(labels[index]);
              let cmds = label.replace(/NVELABELNUMBER/g, labels[index]);
              cmds = cmds.replace(/NVELABELCS/g, labelCS);
              cpj.printerCommands = cmds;
              console.log('printNVEBarcode', cmds);
              cpj.sendToClient();
            }
          } else {
            console.log('printing ZPL labels');
            console.log('labels', labels);
            let label;
            if (printerResolution === '600 dpi') {
              label = zplBarcodeLabel600dpi;
            } else if (printerResolution === '300 dpi') {
              label = zplBarcodeLabel300dpi;
            } else {
              label = zplBarcodeLabel203dpi;
            }

            for (let index = 0; index < labels.length; index++) {
              let cmds = label.replace(/NVELABELNUMBER/g, labels[index]);
              cmds = cmds.replace(/NVELABELCS/g, labels[index]);
              cpj.printerCommands = cmds;
              console.log('printZPLNVEBarcode', cmds);
              cpj.sendToClient();
            }
          }
        }
      }
    }
  }

  /**
   * @description Print Article label
   *  @param data any
   */
  articleLabel(data) {
    console.log('data sent to be printed on article label template: ', data);
    if (this.initializePrinter()) {
      JSPM.JSPrintManager.start(true, this.mySmallPrinter.host, this.mySmallPrinter.port).then((started: any) => {
        console.log('print service started ... do print');
        this.checkingIfWSisOpenAndStartPrinting('ArticleLabel', data);
      });
    } else {
      this.checkingIfWSisOpenAndStartPrinting('ArticleLabel', data);
    }
  }


  doPrintArticleLabel(data) {
    // Print only if device is on-line
    const printer = this.mySmallPrinter;
    console.log('print article label');
    console.log('printer on doPrintArticleLabel(): ', printer);

    if (printer) {
      const printerLanguage = printer.printerLanguage;
      const printerResolution = printer.printerResolution;
      this.printer = printer.name;
      if (navigator.onLine) {
        const {label, article} = data;

        if (this.jspmWSStatus()) {
          // Create a ClientPrintJob
          const cpj = new JSPM.ClientPrintJob();
          cpj.clientPrinter = new JSPM.InstalledPrinter(this.printer);
          // Set Printer type (Refer to the help, there many of them!)

          if (printerLanguage === 'IPL') {
            const templateForIPLArticleLabel = articleLabel;
            let labelCS = article.bdArticleNumber;
            let barcodeNumber = article.bdArticleNumber;
            // article numbers are only ever 8 digits
            // must now use leading 9 and make checksum SDA-272
            if (labelCS.length % 2 === 0) {
              labelCS = '9' + labelCS;
              barcodeNumber = labelCS;
              labelCS = this.addCheckSum(labelCS);
            } else {
              labelCS = this.addCheckSum(labelCS);
            }
            for (let i = 0; i < label.labelQuantity; i++) {
              let cmds = templateForIPLArticleLabel.replace(/ARTICLEBDNO/g, article.bdArticleNumber);
              cmds = cmds.replace(/PURCHASEORDERNO/g, article.bdPurchaseOrderNumber);
              cmds = cmds.replace(/DESIGNATION/g, article.bdArticleName.length < 24 ? article.bdArticleName : article.bdArticleName.substring(0, 24));
              cmds = cmds.replace(/QTY/g, label.numberOfArticles);
              cmds = cmds.replace(/UNIT/g, article.quantityUnit);
              cmds = cmds.replace(/ARTICLENO/g, barcodeNumber);
              cmds = cmds.replace(/REFEREZNO/g, article.bdArticleNumber);
              cmds = cmds.replace(/SUPPLIERORDER/g, article.supplierABNumber === undefined || article.supplierABNumber === 'undefined' ? '' : article.supplierABNumber);
              cmds = cmds.replace(/BDORDERNO/g, article.bdOrderNumber === undefined || article.bdOrderNumber === 'undefined' ? '' : article.bdOrderNumber);
              console.log('articleIPLLabel: ', cmds);
              cpj.printerCommands = cmds;
              cpj.sendToClient();
            }
          } else {
            let templateForZPLArticleLabel;
            if (printerResolution === '600 dpi') {
              templateForZPLArticleLabel = zplArticleLabel600dpi;
            } else if (printerResolution === '300 dpi') {
              templateForZPLArticleLabel = zplArticleLabel300dpi;
            } else {
              templateForZPLArticleLabel = zplArticleLabel203dpi;
            }
            const barcodeNumber = article.bdArticleNumber;
            for (let i = 0; i < label.labelQuantity; i++) {
              let cmds = templateForZPLArticleLabel.replace(/ARTICLEBDNO/g, article.bdArticleNumber);
              cmds = cmds.replace(/PURCHASEORDERNO/g, article.bdPurchaseOrderNumber);
              cmds = cmds.replace(/DESIGNATION/g, article.bdArticleName.length < 24 ? article.bdArticleName : article.bdArticleName.substring(0, 24));
              cmds = cmds.replace(/QTY/g, label.numberOfArticles);
              cmds = cmds.replace(/UNIT/g, article.quantityUnit);
              cmds = cmds.replace(/ARTICLENO/g, barcodeNumber);
              cmds = cmds.replace(/REFEREZNO/g, article.bdArticleNumber);
              cmds = cmds.replace(/SUPPLIERORDER/g, article.supplierABNumber === undefined || article.supplierABNumber === 'undefined' ? '' : article.supplierABNumber);
              cmds = cmds.replace(/BDORDERNO/g, article.bdOrderNumber === undefined || article.bdOrderNumber === 'undefined' ? '' : article.bdOrderNumber);
              console.log('articleZPLLabel: ', cmds);
              cpj.printerCommands = cmds;
              cpj.sendToClient();
            }
          }
        }
      }
    }
  }


  unitLabels(data: Nve[]) {
    console.log('starting to print unitLabels');
    if (this.initializePrinter()) {
      setTimeout(() => {
      console.log('initialized printer');
      JSPM.JSPrintManager.start(true, this.mySmallPrinter.host, this.mySmallPrinter.port).then((started: any) => {
        console.log('print service started ... do print');
        this.checkingIfWSisOpenAndStartPrinting('unitLabels', data);

      });
      },500);
    } else {
      setTimeout(() => {
        this.checkingIfWSisOpenAndStartPrinting('unitLabels', data);
    },500);
    }
  }

  /**
   * @description Print Unit label and Content
   *  @param data Nve
   */
  unitLabel(data: Nve) {
    console.log('starting to print unitLabel');
    if (this.initializePrinter()) {
      setTimeout(() => {
        console.log('initialized printer', data.nveNumber);
        JSPM.JSPrintManager.start(true, this.mySmallPrinter.host, this.mySmallPrinter.port).then((started: any) => {
          console.log('print service started ... do print');
          this.checkingIfWSisOpenAndStartPrinting('unitLabel', data);

        });
      }, 500);
    } else {
      setTimeout(() => {
        this.checkingIfWSisOpenAndStartPrinting('unitLabel', data);
      }, 500);
    }
  }

  doPrintUnitLabel(data: Nve) {
    console.log(' IN doPrintUnitLabel', data)
    console.log('unitLabel', this.myBigPrinter);
    console.log('address number is ' + data.assignedPurchaseOrderItems[0].deliveryAddressNumber);
    console.log('mandate number value is ' + data.mandateNumber);

    let companyAddressName = this.chooseCompanyAddressName(data.mandateNumber, data.assignedPurchaseOrderItems[0].deliveryAddressNumber);
    console.log('companyAddressName: ', companyAddressName)

    if (this.myBigPrinter) {
      this.printer = this.myBigPrinter;
      console.log('bigLabel printer unitLabel', this.printer);
    } else {
      if (this.mySmallPrinter) {
        this.printer = this.mySmallPrinter;
        console.log('smallLabel printer unitLabel', this.printer);
      } else {
        return;
      }
    }
    if (!this.jspmWSStatus()) {
      console.log('Print went wrong in unitLabel');
      return;
    }

    if (navigator.onLine) {
      this.actualLogicForPrintingOfLabel(data, companyAddressName);
    }
  }

  doPrintUnitLabels(nves: Nve[]) {
    console.log(' IN doPrintUnitLabels', nves)
    console.log('unitLabel', this.myBigPrinter);
    console.log('address number is ' + nves[0].assignedPurchaseOrderItems[0].deliveryAddressNumber);
    console.log('mandate number value is ' + nves[0].mandateNumber);

    let companyAddressName = this.chooseCompanyAddressName(nves[0].mandateNumber, nves[0].assignedPurchaseOrderItems[0].deliveryAddressNumber);

    if (this.myBigPrinter) {
      this.printer = this.myBigPrinter;
      console.log('bigLabel printer unitLabel', this.printer);
    } else {
      if (this.mySmallPrinter) {
        this.printer = this.mySmallPrinter;
        console.log('smallLabel printer unitLabel', this.printer);
      } else {
        return;
      }
    }
    if (!this.jspmWSStatus()) {
      console.log('Print went wrong in unitLabels');
      return;
    }

    if (navigator.onLine) {
      nves.forEach(nve => {
        this.actualLogicForPrintingOfLabel(nve, companyAddressName);
      });

    }
  }

  actualLogicForPrintingOfLabel(unit: Nve, companyAddressName: string) {
    // Split array of assigned elements to new array of five elements
    // So it can be printed as per requirements
    const assignedOrders = [...unit.assignedPurchaseOrderItems];
    const printerLanguage = this.printer.printerLanguage;
    const printerResolution = this.printer.printerResolution;
    const printerName = this.printer.name;
    console.log('in doPrintUnitLabel() printerResolution: ', printerResolution);
    console.log(' in doPrintUnitLabel() printerLang: ', printerLanguage);


    if (unit.assignedPurchaseOrderItems.length > 5) {
      let unitLabelData = [];
      let contentLabelData = [];

      unitLabelData = assignedOrders.slice(0, 5);
      contentLabelData = assignedOrders.slice(5);

      let contentLabelArray = [];
      while (contentLabelData.length > 0) {
        contentLabelArray = [...contentLabelArray, contentLabelData.splice(0, 20)];
      }

      if (printerLanguage === 'IPL') {
        this.prepareAndPrintUnitLabel(unitLabelData, unit, printerName, companyUnitLabelHeaders[companyAddressName][printerLanguage]);
      } else {
        this.prepareAndPrintZPLUnitLabel(unitLabelData, unit, printerName, companyUnitLabelHeaders[companyAddressName][printerLanguage], printerResolution);
      }
      console.log('unitLabelData', unitLabelData);
      // For rest of elements print content label
      contentLabelArray.forEach(element => {
        console.log('contentLabelArray', element, unit);
        if (printerLanguage === 'IPL') {
          this.prepareAndPrintContentLabel(element, unit, printerName, companyContentLabelHeaders[companyAddressName][printerLanguage]);
        } else {
          this.prepareAndPrintZPLContentLabel(element, unit, printerName, companyContentLabelHeaders[companyAddressName][printerLanguage], printerResolution);
        }
      });
    } else {
      console.log(unit.assignedPurchaseOrderItems, unit);
      if (printerLanguage === 'IPL') {
        this.prepareAndPrintUnitLabel(unit.assignedPurchaseOrderItems, unit, printerName, companyUnitLabelHeaders[companyAddressName][printerLanguage]);
      } else {
        this.prepareAndPrintZPLUnitLabel(unit.assignedPurchaseOrderItems, unit, printerName, companyUnitLabelHeaders[companyAddressName][printerLanguage], printerResolution);
      }
    }
  }


  // tslint:disable-next-line:no-shadowed-variable
  prepareAndPrintContentLabel(unitLabel, unit, printerName, contentLabelHeader) {

    const labelHeader = contentLabelHeader;
    const labelOfBody = contentLabelBody;
    const labelOfFooter = contentLabelFooter;
    let labelCS = unit.nveNumber;

    if (labelCS.length % 2 === 0) {
      labelCS = '0' + labelCS;
      labelCS = '0' + this.addCheckSum(labelCS);
    } else {
      labelCS = this.addCheckSum(labelCS);
    }

    if (this.jspmWSStatus()) {
      // Create a ClientPrintJob
      const cpj = new JSPM.ClientPrintJob();
      cpj.clientPrinter = new JSPM.InstalledPrinter(printerName);
      // Set Printer type (Refer to the help, there many of them!)
      let cmds = labelHeader.replace(/SUPPLIERID/g, unit.supplierName); // NVECKS
      cmds = cmds.replace(/BDPURCHASEORDERNO/g,  unit.bdPurchaseOrderNumber);
      cmds = cmds.replace(/NVENUMBER/g, unit.nveNumber);
      cmds = cmds.replace(/BDORDERNUMBER/g, unit.deliveryType === 1 ?  unit.bdOrderNumber + ' D' : unit.bdOrderNumber);
      let LabelLine = 373;
      let incrementRow = 15;

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < unitLabel.length; i++) {
        const assignedOrderIitem = unitLabel[i];
        cmds += labelOfBody;


        cmds = cmds.replace(/ASSIQTY/g, assignedOrderIitem.quantity);
        cmds = cmds.replace(/UNITQTY/g, assignedOrderIitem.quantityUnit);
        cmds = cmds.replace(/ASSIARTBDNUMBER/g, assignedOrderIitem.bdArticleNumber);
        cmds = cmds.replace(/ASSIDESBD/g, assignedOrderIitem.bdArticleName);

        cmds = cmds.replace(/UNITBODYFIRST/g, incrementRow.toString());
        incrementRow++;
        cmds = cmds.replace(/UNITBODYSECOND/g, incrementRow.toString());
        incrementRow++;
        cmds = cmds.replace(/UNITBODYTHIRD/g, incrementRow.toString());
        cmds = cmds.replace(/LABELLINE/g, LabelLine.toString());
        LabelLine += 41;
        incrementRow++;
      }
      cmds += labelOfFooter;
      console.log('prepareAndPrintContentLabel', cmds);
      cpj.printerCommands = cmds;
      cpj.sendToClient();
    } else {
      console.log('Print went wrong in prepareAndPrintContentLabel');
    }

  }
  prepareAndPrintZPLContentLabel(unitLabel, unit, printerName, company, printerResolution) {
    let labelHeader;
    let labelOfBody;
    let labelOfFooter;
    let contentLabelFirstCode;
    let contentLabelSecondCode;
    let incrementRow;
    if (company === 'Hellman') {
      if (printerResolution === '600 dpi') {
        labelHeader = zplHellmanContentLabelHeader600dpi;
        labelOfFooter = zplHellmanContentLabelFooter600dpi;
        labelOfBody = zplContentLabelBody600dpi;
        contentLabelFirstCode = 1369;
        contentLabelSecondCode = 1288;
        incrementRow = 116;

      } else if (printerResolution === '300 dpi') {
        labelHeader = zplHellmanContentLabelHeader300dpi;
        labelOfFooter = zplHellmanContentLabelFooter300dpi;
        labelOfBody = zplContentLabelBody300dpi;
        contentLabelFirstCode = 685;
        contentLabelSecondCode = 642;
        incrementRow = 58;
      } else {
        labelHeader = zplHellmanContentLabelHeader203dpi;
        labelOfFooter = zplHellmanContentLabelFooter203dpi;
        labelOfBody = zplContentLabelBody203dpi;
        contentLabelFirstCode = 456;
        contentLabelSecondCode = 426;
        incrementRow = 39;
      }
    } else if(company === 'BD') {
      if (printerResolution === '600 dpi') {
      labelHeader = zplBDContentLabelHeader600dpi;
      labelOfFooter = zplBDContentLabelFooter600dpi;
      labelOfBody = zplContentLabelBody600dpi;
      contentLabelFirstCode = 1291;
      contentLabelSecondCode = 1210;
      incrementRow = 116;
      } else if (printerResolution === '300 dpi') {
        labelHeader = zplBDContentLabelHeader300dpi;
        labelOfFooter = zplBDContentLabelFooter300dpi;
        labelOfBody = zplContentLabelBody300dpi;
        contentLabelFirstCode = 646;
        contentLabelSecondCode = 603;
        incrementRow = 58;
      } else {
        labelHeader = zplBDContentLabelHeader203dpi;
        labelOfFooter = zplBDContentLabelFooter203dpi;
        labelOfBody = zplContentLabelBody203dpi;
        contentLabelFirstCode = 430;
        contentLabelSecondCode = 400;
        incrementRow = 39;
      }
    } else if (company === 'BD PowerSystems') {
      if (printerResolution === '600 dpi') {
        labelHeader = zplBDContentLabelHeader600dpi;
        labelOfFooter = zplBDPowerSystemContentLabelFooter600dpi;
        labelOfBody = zplContentLabelBody600dpi;
        contentLabelFirstCode = 1291;
        contentLabelSecondCode = 1210;
        incrementRow = 116;
      } else if (printerResolution === '300 dpi') {
        labelHeader = zplBDContentLabelHeader300dpi;
        labelOfFooter = zplBDPowerSystemContentLabelFooter300dpi;
        labelOfBody = zplContentLabelBody300dpi;
        contentLabelFirstCode = 646;
        contentLabelSecondCode = 603;
        incrementRow = 58;
      } else {
        labelHeader = zplBDContentLabelHeader203dpi;
        labelOfFooter = zplBDPowerSystemContentLabelFooter203dpi;
        labelOfBody = zplContentLabelBody203dpi;
        contentLabelFirstCode = 430;
        contentLabelSecondCode = 400;
        incrementRow = 39;
      }
    }
    if (this.jspmWSStatus()) {
      // Create a ClientPrintJob
      const cpj = new JSPM.ClientPrintJob();
      cpj.clientPrinter = new JSPM.InstalledPrinter(printerName);
      // Set Printer type (Refer to the help, there many of them!)
      let cmds = labelHeader.replace(/SUPPLIERID/g, unit.supplierName);
      cmds = cmds.replace(/BDPURCHASEORDERNO/g,  unit.bdPurchaseOrderNumber);
      cmds = cmds.replace(/NVENUMBER/g, unit.nveNumber);
      cmds = cmds.replace(/BDORDERNUMBER/g, unit.deliveryType === 1 ?  unit.bdOrderNumber + ' D' : unit.bdOrderNumber);

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < unitLabel.length; i++) {
        const assignedOrderIitem = unitLabel[i];
        console.log('assignedOrderIitem in for loop:', assignedOrderIitem);
        cmds += labelOfBody;
        cmds = cmds.replace(/CLBODYARTILCEQTY/g, assignedOrderIitem.quantity);
        cmds = cmds.replace(/CLBODYARTILCEUNIT/g, assignedOrderIitem.quantityUnit);
        cmds = cmds.replace(/CLBODYARTICLENUMBER/g, assignedOrderIitem.bdArticleNumber);
        cmds = cmds.replace(/CLBODYDESCR/g, assignedOrderIitem.bdArticleName);

        cmds = cmds.replace(/CONTENTLABELFIRSTCODE/g, contentLabelFirstCode.toString());
        cmds = cmds.replace(/CONTENTLABELSECONDCODE/g, contentLabelSecondCode.toString());
        contentLabelFirstCode += incrementRow;
        contentLabelSecondCode += incrementRow;
      }
      cmds += labelOfFooter;
      console.log('prepareAndPrintZPLContentLabel: ', cmds);
      cpj.printerCommands = cmds;
      cpj.sendToClient();
    } else {
      console.log('Print went wrong in prepareAndPrintZPLContentLabel');
    }

  }

  prepareAndPrintUnitLabel(unitLabel, unit, printerName, unitLabelHeader) {
    const labelHeader = unitLabelHeader;
    const labelOfBody = unitLabelBody;
    const labelOfFooter = unitLabelFooter;

    let labelCS = unit.nveNumber;
    if (labelCS.length % 2 === 0) {
      labelCS = '0' + labelCS;
      labelCS = '0' + this.addCheckSum(labelCS);
    } else {
      labelCS = this.addCheckSum(labelCS);
    }
    if (this.jspmWSStatus()) {
      // Create a ClientPrintJob
      const cpj = new JSPM.ClientPrintJob();
      cpj.clientPrinter = new JSPM.InstalledPrinter(printerName);
      // Set Printer type (Refer to the help, there many of them!)
      let cmds = labelHeader.replace(/NVENUMBER/g, unit.nveNumber); // NVECKS
      cmds = cmds.replace(/NVECKS/g, labelCS);
      cmds = cmds.replace(/NVELABEL/g, unit.nveNumber);
      cmds = cmds.replace(/PACKINGTYPE/g, this.translate.instant('packaging-types.' + unit.packagingType));
      cmds = cmds.replace(/UNITLENGTH /g, unit.lengthNum);
      cmds = cmds.replace(/UNITWIDTH/g, unit.widthNum);
      cmds = cmds.replace(/UNITHEIGHT/g, unit.heightNum);
      cmds = cmds.replace(/UNITWEIGHT/g, unit.weightNum);
      let incrementRow = 28;
      let LabelLine = 949;

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < unitLabel.length; i++) {
        const assignedOrderIitem = unitLabel[i];
        cmds += labelOfBody;


        cmds = cmds.replace(/ASSIQTY/g, assignedOrderIitem.quantity);
        cmds = cmds.replace(/UNITQTY/g, assignedOrderIitem.quantityUnit);
        cmds = cmds.replace(/ASSIARTBDNO/g, assignedOrderIitem.bdArticleNumber);
        cmds = cmds.replace(/ASSIDESBD/g, assignedOrderIitem.bdArticleName);

        cmds = cmds.replace(/UNITBODYFIRST/g, incrementRow.toString());
        incrementRow++;
        cmds = cmds.replace(/UNITBODYSECOND/g, incrementRow.toString());
        incrementRow++;
        cmds = cmds.replace(/UNITBODYTHIRD/g, incrementRow.toString());
        incrementRow++;
        cmds = cmds.replace(/LABELLINE/g, LabelLine.toString());
        LabelLine += 41;

      }
      cmds += labelOfFooter;
      cmds = cmds.replace(/BDPURCHASEORDERNO/g, unit.bdPurchaseOrderNumber);
      cmds = cmds.replace(/SUPLIERID/g, unit.supplierName);
      cmds = cmds.replace(/BDORDERNO/g, unit.deliveryType === 1 ?  unit.bdOrderNumber + ' D' : unit.bdOrderNumber);

      cmds = cmds.replace(/UNITFOOTERFIRST/g, incrementRow.toString());
      incrementRow++;
      cmds = cmds.replace(/UNITFOOTERSECOND/g, incrementRow.toString());
      incrementRow++;
      cmds = cmds.replace(/UNITFOOTERTHIRD/g, incrementRow.toString());
      console.log('prepareAndPrintUnitLabel', cmds);

      cpj.printerCommands = cmds;
      cpj.sendToClient();
    } else {
      console.log('Print went wrong in prepareAndPrintUnitLabel');
    }
  }
  prepareAndPrintZPLUnitLabel(unitLabel, unit, printerName, company, printerResolution) {
    let labelHeader;
    let labelOfBody;
    let labelOfFooter;
    let unitLabelFirstCode;
    let unitLabelSecondCode;
    let incrementRow;
    if (company === 'Hellman') {
      if (printerResolution === '600 dpi') {
        labelHeader = zplHellmanUnitLabelHeader600dpi;
        labelOfFooter = zplHellmanUnitLabelFooter600dpi;
        labelOfBody = zplUnitLabelBody600dpi;
        unitLabelFirstCode = 2826;
        unitLabelSecondCode = 2745;
        incrementRow = 116;
      } else if (printerResolution === '300 dpi') {
        labelHeader = zplHellmanUnitLabelHeader300dpi;
        labelOfFooter = zplHellmanUnitLabelFooter300dpi;
        labelOfBody = zplUnitLabelBody300dpi;
        unitLabelFirstCode = 1414;
        unitLabelSecondCode = 1371;
        incrementRow = 58;
      } else {
        labelHeader = zplHellmanUnitLabelHeader203dpi;
        labelOfFooter = zplHellmanUnitLabelFooter203dpi;
        labelOfBody = zplUnitLabelBody203dpi;
        unitLabelFirstCode = 943;
        unitLabelSecondCode = 913;
        incrementRow = 39;
      }
    } else if(company === 'BD') {
      if (printerResolution === '600 dpi') {
        labelHeader = zplBDUnitLabelHeader600dpi;
        labelOfFooter = zplBDUnitLabelFooter600dpi;
        labelOfBody = zplUnitLabelBody600dpi;
        unitLabelFirstCode = 2748;
        unitLabelSecondCode = 2667;
        incrementRow = 116;
      } else if (printerResolution === '300 dpi') {
        labelHeader = zplBDUnitLabelHeader300dpi;
        labelOfFooter = zplBDUnitLabelFooter300dpi;
        labelOfBody = zplUnitLabelBody300dpi;
        unitLabelFirstCode = 1375;
        unitLabelSecondCode = 1332;
        incrementRow = 58;
      } else {
        labelHeader = zplBDUnitLabelHeader203dpi;
        labelOfFooter = zplBDUnitLabelFooter203dpi;
        labelOfBody = zplUnitLabelBody203dpi;
        unitLabelFirstCode = 917;
        unitLabelSecondCode = 887;
        incrementRow = 39;
      }
    } else if(company === 'BD PowerSystems'){
      if (printerResolution === '600 dpi') {
        labelHeader = zplBDPowerSystemUnitLabelHeader600dpi;
        labelOfFooter = zplBDUnitLabelFooter600dpi;
        labelOfBody = zplUnitLabelBody600dpi;
        unitLabelFirstCode = 2748;
        unitLabelSecondCode = 2667;
        incrementRow = 116;
      } else if (printerResolution === '300 dpi') {
        labelHeader = zplBDPowerSystemUnitLabelHeader300dpi;
        labelOfFooter = zplBDUnitLabelFooter300dpi;
        labelOfBody = zplUnitLabelBody300dpi;
        unitLabelFirstCode = 1375;
        unitLabelSecondCode = 1332;
        incrementRow = 58;
      } else {
        labelHeader = zplBDPowerSystemUnitLabelHeader203dpi;
        labelOfFooter = zplBDUnitLabelFooter203dpi;
        labelOfBody = zplUnitLabelBody203dpi;
        unitLabelFirstCode = 917;
        unitLabelSecondCode = 887;
        incrementRow = 39;
      }
    }
    const labelCS = unit.nveNumber;
    if (this.jspmWSStatus()) {
      // Create a ClientPrintJob
      const cpj = new JSPM.ClientPrintJob();
      cpj.clientPrinter = new JSPM.InstalledPrinter(printerName);
      // Set Printer type (Refer to the help, there many of them!)
      let cmds = labelHeader.replace(/NVENUMBER/g, unit.nveNumber);
      cmds = cmds.replace(/NVECKS/g, labelCS);
      cmds = cmds.replace(/NVELABEL/g, unit.nveNumber);
      cmds = cmds.replace(/PACKINGTYPE/g, this.translate.instant('packaging-types.' + unit.packagingType));
      cmds = cmds.replace(/UNITLENGTH /g, unit.lengthNum);
      cmds = cmds.replace(/UNITWIDTH/g, unit.widthNum);
      cmds = cmds.replace(/UNITHEIGHT/g, unit.heightNum);
      cmds = cmds.replace(/UNITWEIGHT/g, unit.weightNum);
      cmds = cmds.replace(/BDPURCHASEORDERNO/g, unit.bdPurchaseOrderNumber);
      cmds = cmds.replace(/SUPLIERID/g, unit.supplierName);
      cmds = cmds.replace(/BDORDERNO/g, unit.deliveryType === 1 ?  unit.bdOrderNumber + ' D' : unit.bdOrderNumber);

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < unitLabel.length; i++) {
        const assignedOrderIitem = unitLabel[i];
        cmds += labelOfBody;
        cmds = cmds.replace(/ULBODYARTICLEQTY/g, assignedOrderIitem.quantity);
        cmds = cmds.replace(/ULBODYARTICLEUNIT/g, assignedOrderIitem.quantityUnit);
        cmds = cmds.replace(/ULBODYARTICLENUMBER/g, assignedOrderIitem.bdArticleNumber);
        cmds = cmds.replace(/ULBODYDESCR/g, assignedOrderIitem.bdArticleName);

        cmds = cmds.replace(/UNITLABELFIRSTCODE/g, unitLabelFirstCode.toString());
        cmds = cmds.replace(/UNITLABELSECONDCODE/g, unitLabelSecondCode.toString());
        unitLabelFirstCode += incrementRow;
        unitLabelSecondCode += incrementRow;
      }
      cmds += labelOfFooter;
      console.log('prepareAndPrintZPLUnitLabel: ', cmds);

      cpj.printerCommands = cmds;
      cpj.sendToClient();
    } else {
      console.log('Print went wrong in prepareAndPrintZPLUnitLabel!');
    }
  }

  printerTestPage(printer: Printer) {

    this.initializePrinterTest(printer);

    if (environment.licenseenabled) {
      JSPM.JSPrintManager.license_url = `${environment.sdaSupplierApiHost}/api/suppliers/supplier/jspmlicense`;
      console.log('JSPM.JSPrintManager.license_url', `${environment.sdaSupplierApiHost}/api/suppliers/supplier/jspmlicense`);
    } else {

      JSPM.JSPrintManager.cache_license_at_start = false;
      console.log('disable cache_license_at_start');
    }


    JSPM.JSPrintManager.auto_reconnect = false;
    JSPM.JSPrintManager.start(true, printer.host, printer.port).then((started: any) => {
      console.log('check printer host and port::',printer.host,'///',printer.port);
      console.log('is it started started? ',started);
      JSPM.JSPrintManager.getPrinters().then((myPrinters: string[]) => {
        console.log('to check myPrinters in printerTestPage:: ',myPrinters);
      });

      this.doTestPrint(printer);

    });

  }

  doTestPrint(printer: Printer) {


    let cmds;
    if (printer.type === 'biglabel') {
      if (printer.printerLanguage === 'IPL') {
        cmds = testBigLabel;
      } else {
        const printerResolution = printer.printerResolution;
        console.log('printerResolution in zpl test big label: ', printerResolution);
        if (printerResolution === '600 dpi') {
          cmds = zplTestBigLabel600dpi;
        } else if (printerResolution === '300 dpi') {
          cmds = zplTestBigLabel300dpi;
        } else {
          cmds = zplTestBigLabel203dpi;
        }
      }
    } else {
      if (printer.printerLanguage === 'IPL') {
        cmds = testSmallLabel;
      } else {
        const printerResolution = printer.printerResolution;
        console.log('printerResolution in zpl test small label: ', printerResolution);
        if (printerResolution === '600 dpi') {
          cmds = zplTestSmallLabel600dpi;
        } else if (printerResolution === '300 dpi') {
          cmds = zplTestSmallLabel300dpi;
        } else {
          cmds = zplTestSmallLabel203dpi;
        }
      }
    }

    const cpj = new JSPM.ClientPrintJob();
    console.log('.. printer job sending to', printer.name);
    cpj.clientPrinter = new JSPM.InstalledPrinter(printer.name);
    // Set Printer type (Refer to the help, there many of them!)

    cpj.printerCommands = cmds;
    cpj.sendToClient().then((sent: any) => {
      console.log('.. printer job sent');
      JSPM.JSPrintManager.stop();
      console.log('.. stop the test print service');
    });
  }

  addCheckSum(num) {
    const numArr = Array.from(String(num), Number);
    let check;
    const sum = numArr.reduce((acc, c, i) => {
      i % 2 !== 0 ? acc += c : acc += (c * 3);
      return acc;
    }, 0);
    check = 10 - (sum % 10);
    if (check === 10) {
      check = 0;
    }
    numArr.push(check);
    return numArr.reduce((acc, n, i) => {
      return acc * 10 + n;
    }, 0).toString();
  }

  chooseCompanyAddressName(mandateNumber: number, deliveryAddressNumber: number) {
    let companyAddressName = '';
    if (mandateNumber) {
      if (mandateNumber == 20) {
        companyAddressName = 'Hellman'
      } else if (mandateNumber == 11) {
        companyAddressName = 'BD'
      } else if (mandateNumber == 25) {
        companyAddressName = 'BD PowerSystems'
      }
    } else {
      if (deliveryAddressNumber == 485088 || deliveryAddressNumber == 613862) {
        companyAddressName = 'Hellman'
      } else if (deliveryAddressNumber == 843) {
        companyAddressName = 'BD'
      } else if (deliveryAddressNumber == 1838766 || deliveryAddressNumber == 1842652) {
        //1842652 is id on prod, 1838766 is on TEST env
        companyAddressName = 'BD PowerSystems';
      }
    }
    console.log('companyAddressName: ', companyAddressName);
    return companyAddressName;
  }
  checkingIfWSisOpenAndStartPrinting(jobType, data) {
    if (typeof JSPM.JSPrintManager.websocket_status === 'undefined' ||
      JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {

      console.log(`.. printer connection still closed. Queue job ${jobType}`);
      JSPM.JSPrintManager.WS.onStatusChanged = () => {
        if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
          this.callActualDoPrintMethodDependingOnJobType(jobType, data);
          JSPM.JSPrintManager.WS.onStatusChanged = null;
          console.log(`.. print job executed. Cancel callback ${jobType}`);

        }
      };
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      this.callActualDoPrintMethodDependingOnJobType(jobType, data);
    }
  }

  callActualDoPrintMethodDependingOnJobType(jobType, data) {
    if (jobType === 'NveBarcode') {
      this.doPrintNVEBarcode(data);
    } else if (jobType === 'ArticleLabel') {
      this.doPrintArticleLabel(data);
    } else if (jobType === 'unitLabels') {
      this.doPrintUnitLabels(data);
    } else if (jobType === 'unitLabel') {
      this.doPrintUnitLabel(data);
    }
  }
}
