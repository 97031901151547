import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
@Component({
  selector: 'app-article-label-dialog',
  templateUrl: './article-label-dialog.component.html',
  styleUrls: ['./article-label-dialog.component.scss']
})
export class ArticleLabelDialogComponent implements OnInit {

  printLabel = {
    numberOfArticles: 0,
    labelQuantity: 1,
  };
  article: any;
  constructor(
    private dialogRef: MatDialogRef<ArticleLabelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {

    console.log(this.data);
    this.article = { ...this.data.orderArticle };
    this.printLabel.numberOfArticles = this.article.articleQtyOrdered;

  }

  changeValueFromInput(value, property) {
    this.printLabel[property] = +value;
  }

  save() {
    const data = {
      article: this.article,
      label: this.printLabel
    };

    this.dialogRef.close(data);
  }

  close() {
    this.dialogRef.close();
  }

}
