import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AssigneArticleDialogComponent} from '../assigne-article-dialog/assigne-article-dialog.component';

@Component({
  selector: 'app-pack-more-than-order-dialog',
  templateUrl: './pack-more-than-order-dialog.component.html',
  styleUrls: ['./pack-more-than-order-dialog.component.scss']
})
export class PackMoreThanOrderDialogComponent implements OnInit {
  article: any;
  newOrderedQty: any;
  constructor(
    private dialogForPackingMoreThanOrder: MatDialogRef<PackMoreThanOrderDialogComponent>,
    private dialogRef: MatDialogRef<AssigneArticleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
  }
  ngOnInit() {
    this.article = this.data.article;
    this.newOrderedQty = this.data.newOrderedQty;
  }
  close() {
  this.dialogForPackingMoreThanOrder.close();
  }
  confirm() {
    this.dialogForPackingMoreThanOrder.close({qty: this.newOrderedQty, article: this.article});
  }
}
