import {Component, OnInit, OnDestroy, ElementRef, AfterViewInit, AfterContentInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NveBarcodeDialogComponent } from '../nve-barcode-dialog/nve-barcode-dialog.component';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PrintLabelService } from 'src/app/services/print-label.service';
// import {environment} from '../environments/environment';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';
declare var Feedback: any;
declare var JSPM: any;

@Component({
  selector: 'app-navi-bar',
  templateUrl: './navi-bar.component.html',
  styleUrls: ['./navi-bar.component.scss']
})
export class NaviBarComponent implements OnInit, OnDestroy {

  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  private user: any;

  constructor(
    private print: PrintLabelService,
    private translate: TranslateService,
    private router: Router,
    public dialog: MatDialog) { }

  ngOnInit() {
    // this.createFeedbackButton();
  }

  openNVE() {
    const dialogRef = this.dialog.open(NveBarcodeDialogComponent,
      {
        maxWidth: '100vw',
        width: '750px',
        maxHeight: '90vw',
        position: { top: '100px' },
      }
    );

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(resault => {
        if (resault) {
          console.log(resault, JSPM);
          this.print.printNVEBarcode(resault);
        }

        console.log('TNveBarcodeDialogComponent - he dialog was closed');

      });
  }
  ngOnDestroy(): void {
    console.log('NaviBarComponent Destroy');
    this.destroy$.next(true);
  }
  openFeedback() {
    const buttonsCollection = document.getElementsByClassName('feedback-bottom-right');
    this.createFeedbackButton();
    const button = buttonsCollection[buttonsCollection.length - 1] as HTMLButtonElement;
    button.click();

  }
  createFeedbackButton() {
    // css for feedback is in feedback-v25.css, but all functionalities are called from index.html
    // src="https://my-stage.bigdutchman.com/o/com.bigdutchman.feedback.report/js/feedback-custom-v45.js"
    const label = this.translate.instant('feedback-button.label');
    const header = this.translate.instant('feedback-button.header');
    const messageSuccess = this.translate.instant('feedback-button.messageSuccess');
    const messageErrorTitle = this.translate.instant('feedback-button.messageErrorTitle');
    const messageError = this.translate.instant('feedback-button.messageError');
    const closeLabel = this.translate.instant('feedback-button.closeLabel');
    const nextLabel = this.translate.instant('feedback-button.nextLabel');
    const backLabel = this.translate.instant('feedback-button.cancelLabel');
    const backToSelectionLabel = this.translate.instant('feedback-button.backToSelectionLabel');
    const cancelLabel = this.translate.instant('feedback-button.cancelLabel');
    const reviewLabel = this.translate.instant('feedback-button.reviewLabel');
    const sendLabel = this.translate.instant('feedback-button.sendLabel');
    const textAreaLabel = this.translate.instant('feedback-button.textAreaLabel');
    const radioLabel1 = this.translate.instant('feedback-button.radioLabel1');
    const radioLabel2 = this.translate.instant('feedback-button.radioLabel2');
    const radioLabel3 = this.translate.instant('feedback-button.radioLabel3');
    const radioLabel4 = this.translate.instant('feedback-button.radioLabel4');
    const reviewScreenLabel = this.translate.instant('feedback-button.reviewScreenLabel');
    const successTitle = this.translate.instant('feedback-button.successTitle');

    const userEmailLabel = this.translate.instant('feedback-button.userEmailLabel');
    const userFirstNameLabel = this.translate.instant('feedback-button.userFirstNameLabel');
    const userLastNameLabel = this.translate.instant('feedback-button.userLastNameLabel');
    const userInfoScreenLabel = this.translate.instant('feedback-button.userInfoScreenLabel');
    const  addAttachmentLabel = this.translate.instant('feedback-button.addAttachmentLabel');
    const officeFormatsLabel = this.translate.instant('feedback-button.officeFormatsLabel');
    const imageFormatsLabel = this.translate.instant('feedback-button.imageFormatsLabel');
    const maxFileSizeLabel = this.translate.instant('feedback-button.maxFileSizeLabel');
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    console.log('USER from local storage in creating feedback button', this.user);

    const feedbackUser = {
      username: this.user.supplierId,
      email: this.user.email,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      id: this.user.supplierId
    };
    // this creates only physical representation of button
    Feedback({
        h2cPath: environment.h2cPath,
        url : environment.url,
        auth : {
          url: environment.authUrl,
          clientId : environment.oauth2ClientId,
          clientSecret : environment.oauth2ClientSecret
        },
        affectedApplications: ['SDA'],
        label: label,
        header: header,
        nextLabel: nextLabel,
        backLabel: backLabel,
        backToSelectionLabel: backToSelectionLabel,
        cancelLabel: cancelLabel,
        reviewLabel: reviewLabel,
        sendLabel: sendLabel,
        closeLabel: closeLabel,
        messageError: messageError,
        messageErrorTitle: messageErrorTitle,
        textAreaLabel: textAreaLabel,
        radioLabel1: radioLabel1,
        radioLabel2: radioLabel2,
        radioLabel3: this.router.url.startsWith('/packing') ? radioLabel3 : ' ',
        radioLabel4: radioLabel4,
        radioLabel5: ' ',
        radioLabel6: ' ',
        successTitle: successTitle,
        successLabel: messageSuccess,
        reviewScreenLabel: reviewScreenLabel,
        user: feedbackUser,
        radioValue3: '13200',
        userEmailLabel : userEmailLabel,
        userFirstNameLabel : userFirstNameLabel,
        userLastNameLabel : userLastNameLabel,
        userInfoScreenLabel : userInfoScreenLabel,
        addAttachmentLabel : addAttachmentLabel,
        officeFormatsLabel : officeFormatsLabel,
        imageFormatsLabel : imageFormatsLabel,
        maxFileSizeLabel : maxFileSizeLabel,
        reCaptcha : {}
      });
    console.log('Feedback:', Feedback);
    const buttonsCollection = document.getElementsByClassName('feedback-bottom-right');
    const button = buttonsCollection[buttonsCollection.length - 1] as HTMLButtonElement;
    //button is in code set to be shown in front of logout btn but with these 2 lines btn will be hidden and below logout btn
    button.style.zIndex = '-1';
    button.style.display = 'none';
  }

}
