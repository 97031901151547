import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../shared/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {
  private curentSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  curent$ = this.curentSubject.asObservable();

  private labelsSubject: BehaviorSubject<any> = new BehaviorSubject<number[]>([]);
  labels$ = this.labelsSubject.asObservable();


  constructor(private http: HttpClient, private snackBar: MatSnackBar) { }

  handleError = (error: any) => {
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      data: `${error.message}`
    });
    return throwError(error);
  }

  getCurrent(id: string) {
    const headers = new HttpHeaders({ Authorization: 'Basic ' + btoa(environment.techuser + ':' + environment.sdaSupplierApiPassword) });

    this.http.get(`${environment.sdaSupplierApiHost}/api/suppliers/supplier/${id}/labels/current`,{headers})
      .subscribe((nve: number) => {
        this.curentSubject.next(nve);
      },
        err => {
          this.handleError(err);
        }
      );
  }

  getLabels(id: string, count: number): Observable<number[]> {
    const headers = new HttpHeaders({ Authorization: 'Basic ' + btoa(environment.techuser + ':' + environment.sdaSupplierApiPassword) });
    return this.http.post(`${environment.sdaSupplierApiHost}/api/suppliers/supplier/${id}/labels/${count}`, null,{headers})
      .pipe(
        map((labels: number[]) => {
          return labels;
        }),
        catchError(this.handleError)
      );
  }

  getNextShipping(id: string): Observable<number[]> {
    const headers = new HttpHeaders({ Authorization: 'Basic ' + btoa(environment.techuser + ':' + environment.sdaSupplierApiPassword) });
    return this.http.post(`${environment.sdaSupplierApiHost}/api/suppliers/supplier/${id}/shipping/next`, null,{headers})
      .pipe(
        map((shiping: any) => {
          return shiping;
        }),
        catchError(this.handleError)
      );
  }
}
