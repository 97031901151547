import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {PackMoreThanOrderDialogComponent} from '../pack-more-than-order-dialog/pack-more-than-order-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/internal/operators/takeUntil';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';


@Component({
  selector: 'app-assigne-article-dialog',
  templateUrl: './assigne-article-dialog.component.html',
  styleUrls: ['./assigne-article-dialog.component.scss']
})
export class AssigneArticleDialogComponent implements OnInit, OnDestroy {

  nve: number;
  printLabel = {
    articleQty: 0
  };
  article: any;
  isDecimal: boolean;
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private dialogRef: MatDialogRef<AssigneArticleDialogComponent>,
    private dialogForPackMoreThanOrder: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {

    console.log("AssigneArticleDialogComponent onInit", this.data);
    this.article = this.data.article;
    if (this.article.quantityUnit === 'ST') {
      this.isDecimal = true;
    }
    this.nve = this.data.nve;
    this.printLabel.articleQty = this.article.articleQtyOrdered - this.article.articleQtyPacked;
    console.log("AssigneArticleDialogComponent this.printLabel.articleQty", this.printLabel.articleQty);

  }

  changeValueFromInput(value) {
    this.printLabel.articleQty = +value;
  }

  proceed() {
    this.dialogRef.close({ qty: this.printLabel.articleQty, article: this.article });
  }

  close() {
    this.dialogRef.close();
  }
  openConfirmDialogForPackingMoreThanOrdered() {
    const article = this.article;
    const newOrderedQty = this.printLabel.articleQty;
    const addArticle = this.dialogForPackMoreThanOrder.open(PackMoreThanOrderDialogComponent,
      {
        maxWidth: '100vw',
        width: '750px',
        minHeight: '40vh',
        position: {top: '60px'},
        data: {article, newOrderedQty},
        disableClose: true,
        autoFocus: false
      }
    );
    addArticle.afterClosed().pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.dialogRef.close({qty: data.qty, article: data.article });
      });
  }
  ngOnDestroy(): void {
    console.log('Assigne article dialog component Destroy');
    this.destroy$.next(true);
  }

}
