<div class="text-center">
  <h2 *ngIf="!showAccessoryButton">
    {{ "final-check-dialog.title" | translate }}
  </h2>
  <h2 *ngIf="showAccessoryButton">
    {{ "final-check-dialog.confirm-title" | translate }}
  </h2>
  <mat-dialog-actions>
    <button
      *ngIf="!showAccessoryButton"
      class="mat-raised-button mat-primary mar-25 "
      (click)="proceed(true)"
    >
      {{ "final-check-dialog.complete" | translate }}
    </button>
    <button
      *ngIf="!showAccessoryButton"
      class="mat-raised-button mat-primary mar-25 "
      (click)="proceed(false)"
    >
      {{ "final-check-dialog.add-accessory-unit" | translate }}
    </button>
    <button
      *ngIf="showAccessoryButton"
      class="mat-raised-button mat-primary mar-25 "
      (click)="proceed(true)"
    >
      {{ "final-check-dialog.confirm-button" | translate }}
    </button>
  </mat-dialog-actions>
</div>
