import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { PrintService } from './services/print.service';
import { LoginService } from './services/login.service';
import { TranslateService } from '@ngx-translate/core';
import {environment} from '../environments/environment';
declare var $: any;
declare var Feedback: any;
declare var reCaptcha: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


  apiToken: string;
  showUnauthorizedMessage: boolean;
  apiResult;
  logoutSuccess: boolean;
  scanner = {
    isScanner: false
  };
  private user: any;

  constructor(
    private swUpdate: SwUpdate,
    public printService: PrintService,
    private loginService: LoginService,
    private translate: TranslateService) {

    translate.addLangs(['en_US', 'de_DE']);
    translate.setDefaultLang('de_DE');
    translate.use('de_DE');
  }

  // @ts-ignore
  ngOnInit(): void {

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('Update available. Load new version?')) {
          window.location.reload();
        }
      });

    }

    localStorage.setItem('scanner', JSON.stringify(this.scanner));
    this.loginService.getToken();
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

}
