<div class="dialog-wrapper">
  <div class="text-center">
    <h2>{{ data.title | translate }}</h2>
    <p>
      <strong>{{ nve }}</strong>
    </p>
    <mat-dialog-content class="content-input"> </mat-dialog-content>
    <mat-dialog-actions>
      <button
        class="mat-raised-button mat-primary mar-25"
        (click)="close(true)"
      >
        {{ "delete-dialog.delete" | translate }}
      </button>
      <button
        class="mat-raised-button mat-primary mar-25"
        (click)="close(false)"
      >
        {{ "delete-dialog.cancel" | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
