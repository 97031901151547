import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-messages-for-laser-scan-dialog',
  templateUrl: './messages-for-laser-scan-dialog.component.html',
  styleUrls: ['./messages-for-laser-scan-dialog.component.scss']
})
export class MessagesForLaserScanDialogComponent implements OnInit, OnDestroy {
  mode;
  nveNumber;
  message;

  constructor(
    private dialogRef: MatDialogRef<MessagesForLaserScanDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.nveNumber = this.data.nveNumber;
    this.mode = this.data.mode;
    console.log(this.mode, this.nveNumber);
    if (this.mode === 'outOfRange') {
      this.message = this.translate.instant('shipping-dialog.nve-not-in-range', { nveNumber: this.nveNumber });
    }
    if (this.mode === 'startsWith0orMorethan9') {
      this.message = this.translate.instant('shipping-dialog.nve-in-list', { nveNumber: this.nveNumber});
    }
    if (this.mode === 'assigned') {
      this.message = this.translate.instant('shipping-dialog.nve-in-shippinglist', { nveNumber: this.nveNumber });
    }
    if (this.mode === 'unassigned') {
      this.message = this.translate.instant('shipping-dialog.nve-in-list', { nveNumber: this.nveNumber });
    }
  }

  ngOnDestroy() {
  }

  closeDialog() {
    setTimeout(() => {
      this.dialogRef.close();

    });
  }
}
