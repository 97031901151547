import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Component({
  selector: 'app-equally-ranked-dialog',
  templateUrl: './equally-ranked-dialog.component.html',
  styleUrls: ['./equally-ranked-dialog.component.scss']
})
export class EquallyRankedDialogComponent implements OnInit, OnDestroy {

  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  articleQuantityPerUnit = 1;
  unitQuantity = 1;
  bdPurchaseOrderNumber: number;
  article: any;
  maxQty: number;

  constructor(
    private suppliersService: SuppliersService,
    private dialogRef: MatDialogRef<EquallyRankedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    console.log(this.data);
    this.bdPurchaseOrderNumber = this.data.purchaseOrder.bdPurchaseOrderNumber;
    this.article = this.data.selectedArticle;
    if (this.article) {
      this.maxQty = this.article.articleQtyOrdered - this.article.articleQtyPacked;
    }


  }

  copyUnit() {
    this.suppliersService.getLabels(this.data.purchaseOrder.supplierId, this.unitQuantity)
      .pipe(takeUntil(this.destroy$))
      .subscribe(labels => {
        this.dialogRef.close({ labels, articleQuantityPerUnit: this.articleQuantityPerUnit, article: this.article });
      });
  }
  changeValueFromInput(val, prop) {
    this[prop] = +val;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
  close() {
    this.dialogRef.close();
  }
}
