import { Component, OnInit, Inject, ViewChild, AfterViewInit, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { BarecodeScannerLivestreamComponent } from 'ngx-barcode-scanner';

import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatInput } from '@angular/material';
import { NvesService } from 'src/app/services/nves.service';
import { PrintLabelService } from 'src/app/services/print-label.service';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-packing-dialog',
  templateUrl: './packing-dialog.component.html',
  styleUrls: ['./packing-dialog.component.scss']
})
export class PackingDialogComponent implements OnInit, OnDestroy {

  scannerMode = true;
  scanner: any;
  nveRange: any;
  inputValue = '';
  purchaseOrder: any;
  msg: string;
  isArticle: boolean;
  isEqually: boolean;
  title: string;
  isAccessory: boolean;
  showStatus = 'scanner-start';
  barcodeNumber: number;
  @ViewChild(BarecodeScannerLivestreamComponent, null) barecodeScanner: BarecodeScannerLivestreamComponent;
  processingSubmit: boolean;



  constructor(
    private nveDb: NvesService,
    private suppliersService: SuppliersService,
    private printService: PrintLabelService,
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
    private dialogRef: MatDialogRef<PackingDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.msg = '';
    this.scanner = JSON.parse(localStorage.getItem('scanner'));
    this.nveRange = JSON.parse(localStorage.getItem('nveRangeConfig'));
    this.scannerMode = this.scanner.isScanner;
    this.purchaseOrder = this.data.purchaseOrder;
    this.isArticle = this.data.isArticle;
    this.isAccessory = this.data.isAccessory;
    this.title = this.data.title;
    this.isEqually = this.data.isEqually;
    this.processingSubmit = false;
    console.log('ngOnInit  -   PackingDialogComponent');
    console.log('.. nveRange', this.nveRange);
    if (this.scannerMode) {
      setTimeout(() => {
        this.showStatus = 'scanner-progress';
        setTimeout(() => {
          if (this.barecodeScanner) {
            this.barecodeScanner.start();
          }
        });
      }, 2000);
    } else {
      setTimeout(() => {
        // this.renderer.selectRootElement('#inputValue').focus();
      }, 2000);
    }




  }


  closeDialog() {
    this.showStatus = 'scanner-finish';
    setTimeout(() => {
      this.dialogRef.close();

    });
  }

  // camera
  onValueChanges(result) {
    this.msg = '';
    if (this.barcodeNumber !== result.codeResult.code) {
      if (this.checkSumBarcode(result.codeResult.code)) {

        this.msg = 'checking';
        this.showStatus = 'scanner-finish';
        if (this.isArticle) {
          if (result.codeResult.code.length % 2 === 0 && result.codeResult.code.charAt(0) === '9' && result.codeResult.code.length === 10) {
            // Remove 9 from first position
            result.codeResult.code = result.codeResult.code.substr(1);

          }
          // Remove checksum number from last position
          console.log('camera scanned article number', result.codeResult.code.slice(0, -1));
          const findMatch = this.data.articleList.find(article => article == result.codeResult.code.slice(0, -1));
          if (findMatch) {
            this.barcodeNumber = result.codeResult.code.slice(0, -1);
            this.msg = '';
          } else {
            this.msg = this.translate.instant('packing-dialog.article-in-list', {articleNo: result.codeResult.code.slice(0, -1)});
          }
        } else if (result.codeResult.code.length === 10 && result.codeResult.code.charAt(0) !== '9') {
          const tempBarcodeNumber = result.codeResult.code.slice(0, -1);
          console.log('nve scan detected', tempBarcodeNumber);
          if (this.nveRange && (+tempBarcodeNumber < this.nveRange.startNVE || +tempBarcodeNumber > this.nveRange.endNVE)) {
            this.msg = this.translate.instant('packing-dialog.nve-not-in-range', {nveNumber: tempBarcodeNumber});
            console.log('nve not in range', tempBarcodeNumber);

          } else {
            console.log('checking nve in use ...', tempBarcodeNumber);
            this.nveDb.findByNveNumber(tempBarcodeNumber).then(res => {
              const temp = res.docs.find(nve => nve.nveNumber == tempBarcodeNumber);
              if (temp) {
                this.msg = this.translate.instant('packing-dialog.nve-in-use', {nveNumber: tempBarcodeNumber});
                console.log('nve in use', tempBarcodeNumber);
              } else {
                this.barcodeNumber = tempBarcodeNumber;
                this.msg = '';
                console.log('barcode accepted', tempBarcodeNumber);
              }
            });
          }

        }
      }

    }
  }

  scanAgain() {
    setTimeout(() => {
      this.showStatus = 'scanner-progress';
      this.barcodeNumber = null;
      setTimeout(() => {
        this.barecodeScanner.start();
      });
    }, 1000);
  }

  confirm() {
    this.showStatus = 'scanner-finish';
  }

  // laser input field submission
  submit() {
    this.msg = '';
    this.processingSubmit = true;
    if (this.isArticle) {
      // Remove checksum number from last position
      console.log('laser article scan detected', this.inputValue);
      // what about leading 9?  laster will remove checksum
      console.log('laser scanned article number', this.inputValue.toString().substring(1));
      const findMatch = this.data.articleList.find(article => article == this.inputValue.toString().substring(1));
      if (findMatch) {
        setTimeout(() => {
          this.dialogRef.close({scanNumber: this.inputValue.toString().substring(1), isEqually: this.isEqually});
        });
      } else {
        this.msg = this.translate.instant('packing-dialog.article-in-list', {articleNo: this.inputValue.toString().substring(1)});
      }
      this.showStatus = 'scanner-finish';
    } else if (this.inputValue.toString().length === 9 && this.inputValue.toString().charAt(0) !== '9') {
      var tempBarcodeNumber = parseInt(this.inputValue);
      console.log('nve scan detected', tempBarcodeNumber);
      this.showStatus = 'scanner-finish';
      this.msg = 'checking';
      if (this.nveRange && (tempBarcodeNumber < this.nveRange.startNVE || tempBarcodeNumber > this.nveRange.endNVE)) {
        console.log('nve out of range', tempBarcodeNumber);
        this.msg = this.translate.instant('packing-dialog.nve-not-in-range', {nveNumber: tempBarcodeNumber});
        this.showStatus = 'scanner-finish';
      } else {
        console.log('checking in use ...', tempBarcodeNumber);
        this.nveDb.findByNveNumber(this.inputValue).then(res => {
          const temp = res.docs.find(nve => nve.nveNumber == this.inputValue);
          if (temp) {
            console.log('nve in use', tempBarcodeNumber);
            this.msg = this.translate.instant('packing-dialog.nve-in-use', {nveNumber: this.inputValue});
          } else {
            console.log('nve ok', tempBarcodeNumber);
            this.msg = '';
            this.barcodeNumber = parseInt(this.inputValue);
            setTimeout(() => {
              this.dialogRef.close({scanNumber: this.barcodeNumber, isEqually: this.isEqually});
            });
          }
        });
      }

    } else {
      this.showStatus = 'scanner-finish';
      this.msg = this.translate.instant('packing-dialog.scan-again');
    }
    this.processingSubmit = false;

  }

  submitBarcode() {
    this.showStatus = 'scanner-finish';
    setTimeout(() => {
      this.dialogRef.close({scanNumber: this.barcodeNumber, isEqually: this.isEqually});
    });

  }

  clearMsg() {
    this.msg = '';
  }

  printNVEBarcode() {
    this.suppliersService.getLabels(this.purchaseOrder.supplierId, 1)
      .subscribe(response => {
        this.printService.printNVEBarcode(response);
      });
    console.log('printNVEBarcode');
  }

  createEquallyUnits() {
    this.showStatus = 'scanner-finish';
    setTimeout(() => {
      this.dialogRef.close({scanNumber: this.barcodeNumber, isEqually: true});

    });
  }

  checkSumBarcode(code) {
    const numArr = Array.from(String(code), Number);
    const check = numArr[numArr.length - 1];
    numArr.splice(-1, 1);
    const sum = numArr.reduce((acc, c, i) => {
      i % 2 !== 0 ? acc += c : acc += (c * 3);
      return acc;
    }, 0);
    let mod = 10 - (sum % 10);
    if (mod === 10) {
      mod = 0;
    }
    return mod === check;
  }

  startScanner() {
    this.scannerMode = !this.scannerMode;
    this.scanner.isScanner = this.scannerMode;

    localStorage.setItem('scanner', JSON.stringify(this.scanner));
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      data: this.scannerMode ? this.translate.instant('snack-bar-message.camera-mode')
        : this.translate.instant('snack-bar-message.scanner-mode')
    });
    if (this.scannerMode) {
      setTimeout(() => {
        this.showStatus = 'scanner-progress';
        this.barcodeNumber = null;

        setTimeout(() => {
          this.barecodeScanner.start();
        });
      });
    } else {

      setTimeout(() => {
        this.renderer.selectRootElement('#inputValue').focus();
      });

    }
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy  -   PackingDialogComponent');
    this.showStatus = 'scanner-finish';
  }


}
