import {Component, OnInit} from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { SplashRoutingModule } from 'src/app/modules/splash/spalsh-routing.module';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss']
})
export class LogoutButtonComponent implements OnInit {

  sloHtml; 

  constructor(
    private loginService: LoginService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}
  

  ngOnInit() {
  }

  doLogout() {
    console.info("User would like to logout ...");
    this.loginService.logout().subscribe(htmlRaw => {
      this.sloHtml = this.sanitizer.bypassSecurityTrustHtml(htmlRaw);

      setTimeout(() => {
        var form = document.getElementById('bd-saml-logout');
        if (form) (form as HTMLFormElement).submit();
      }, 100);


    });
    console.info("User logged out.");
  }
}
