<div class="dialog-wrapper">
  <button (click)="close()" class="close-btn" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <div class="text-center">
    <h2>{{'assigne-article-dialog.title' | translate }}</h2>
    <!--<p [innerHTML]="'assigne-article-dialog.add-article' | translate:{bdArticleNumber: article.bdArticleNumber, nve: nve}">-->
    <!--<p [innerHTML]="'assigne-article-dialog.position' | translate:{position: article.purchaseOrderPosition}"></p>-->

    <p><strong>{{article.bdArticleName}}</strong></p>
    <p><strong>{{article.bdArticleNumber}}</strong></p>
    <p><strong>{{article.supplierArticleNumber}}</strong></p>
    <div>
      {{'assigne-article-dialog.article-qty' | translate }}
      <p *ngIf="article.articleQtyOrdered - article.articleQtyPacked >= 0 "><strong>{{article.articleQtyOrdered - article.articleQtyPacked}}</strong></p>
      <p *ngIf="article.articleQtyOrdered - article.articleQtyPacked < 0 "><strong>{{article.articleQtyPacked}}</strong></p>
    </div>
  </div>
  <mat-dialog-content class="content-input">
    <app-add-remove-input
      [maxNumber]="article.articleQtyOrdered - article.articleQtyPacked"
      [placeholder]="'assigne-article-dialog.article-qty'"
      [value]="printLabel.articleQty"
      [isDecimal]="isDecimal"
      (keyup.enter)="!(printLabel.articleQty < 0 || printLabel.articleQty === null)  && proceed()"
      (changeValue)="changeValueFromInput($event)"
    ></app-add-remove-input>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      [ngClass]="{
        disabled: printLabel.articleQty === null
      }"
      [disabled]="printLabel.articleQty === null"
      class="mat-raised-button mat-primary"
      (click)="proceed()"
    >
      {{'assigne-article-dialog.proceed' | translate }}
    </button>
  </mat-dialog-actions>
</div>
