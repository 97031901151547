import { Component, OnInit, Inject, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelect} from '@angular/material';
import { longStackSupport } from 'q';
import { Logs } from 'selenium-webdriver';
import { PrintLabelService } from 'src/app/services/print-label.service';
import { PACKAGING_TYPES } from 'src/app/constants/packaging-types';

@Component({
  selector: 'app-complete-unit-dialog',
  templateUrl: './complete-unit-dialog.component.html',
  styleUrls: ['./complete-unit-dialog.component.scss']
})
export class CompleteUnitDialogComponent implements OnInit {
  nve: any;
  packingTypeDescription:string;
  specificId:number;
  labels: string[];
  location: string;
  packagingTypes = PACKAGING_TYPES;
  dataArray: any[];
  unitMeasures = {
    packagingType: null,
    heightNum: null,
    lengthNum: null,
    weightNum: null,
    widthNum: null
  };
  @ViewChild('myElement',  {static: false}) firstItem: MatSelect;

  constructor(
    private dialogRef: MatDialogRef<CompleteUnitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private print: PrintLabelService) {

  }

  doFilter(value) {
    this.dataArray = [];
    const filter  =  value.indexOf(' ') > -1 ? value.substr(0,value.indexOf(' ')): value;
    this.dataArray = this.packagingTypes.filter(type => type.id.toString().indexOf(filter.replace(/\D/g,'')) > -1);
  }


  ngOnInit() {
    this.dataArray = this.packagingTypes;
    console.log('CompleteUnitDialogComponent', this);
    if (this.data) {

      const typeOfPac = PACKAGING_TYPES.find(packType => packType.id === this.data.nve.packagingType);
      if (typeOfPac) {
        const {  id, description } = typeOfPac;
        console.log('entering log');
        console.log('id ' +id +' descr'+description);
        this.packingTypeDescription =  id + ' ' + description;
      }
      this.nve = this.data.nve;
      this.labels = this.data.labels;
      this.location = this.data.location;
    }
  }

  printUnitLabel() {
    this.dialogRef.close(true);
  }

  changeSelect(autocomplete) {
    var extractFirstString= autocomplete.option.value.indexOf(' ') > -1 ? autocomplete.option.value.substr(0,autocomplete.option.value.indexOf(' ')): autocomplete.option.value;
    this.specificId = extractFirstString.replace( /^\D+/g, '');

    const typeOfPac = this.packagingTypes.find(packType => packType.id == this.specificId);
    if (typeOfPac) {
      const { length, height, id, width } = typeOfPac;
      this.nve.packagingType = id;
      this.nve.heightNum = height;
      this.nve.lengthNum = length;
      this.nve.widthNum = width;
    }
  }

  backToPacking() {
    this.dialogRef.close(false);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
