import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import * as i0 from "@angular/core";
export class PrintService {
    constructor() {
        this.comp = {
            small: false,
            big: false,
            shippinglist: false
        };
        this.printerListSubject = new BehaviorSubject(null);
        this.printerList$ = this.printerListSubject.asObservable();
        this.isOpen = false;
        this.changes = new EventEmitter();
    }
    print(data) {
        setTimeout(() => {
            console.log('### printer emiting the changes ###');
            this.changes.emit(data);
        });
    }
}
PrintService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PrintService_Factory() { return new PrintService(); }, token: PrintService, providedIn: "root" });
