import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de', localeDeExtra);
const ɵ0 = {}, ɵ1 = {} // Add any data you wish to test if it is passed/used correctly
;
export class AppModule {
}
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export { ɵ0, ɵ1 };
