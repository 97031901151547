/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./print-shipping-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/material/dialog";
import * as i4 from "./print-shipping-dialog.component";
var styles_PrintShippingDialogComponent = [i0.styles];
var RenderType_PrintShippingDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrintShippingDialogComponent, data: {} });
export { RenderType_PrintShippingDialogComponent as RenderType_PrintShippingDialogComponent };
export function View_PrintShippingDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "dialog-wrapper scanner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 4, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i3.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "mat-raised-button mat-primary"], ["style", "margin-top: 10px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.printList() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("print-shipping-dialog.advice-sent")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("print-shipping-dialog.print-shipping-list")); _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("print-shipping-dialog.print")); _ck(_v, 12, 0, currVal_2); }); }
export function View_PrintShippingDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-print-shipping-dialog", [], null, null, null, View_PrintShippingDialogComponent_0, RenderType_PrintShippingDialogComponent)), i1.ɵdid(1, 245760, null, 0, i4.PrintShippingDialogComponent, [i3.MatDialogRef, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrintShippingDialogComponentNgFactory = i1.ɵccf("app-print-shipping-dialog", i4.PrintShippingDialogComponent, View_PrintShippingDialogComponent_Host_0, {}, {}, []);
export { PrintShippingDialogComponentNgFactory as PrintShippingDialogComponentNgFactory };
