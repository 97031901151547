/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./print.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shipping-list-template/shipping-list-template.component.ngfactory";
import * as i3 from "../shipping-list-template/shipping-list-template.component";
import * as i4 from "../services/print.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./print.component";
var styles_PrintComponent = [i0.styles];
var RenderType_PrintComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrintComponent, data: {} });
export { RenderType_PrintComponent as RenderType_PrintComponent };
export function View_PrintComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-shipping-list-template", [], null, null, null, i2.View_ShippingListTemplateComponent_0, i2.RenderType_ShippingListTemplateComponent)), i1.ɵdid(2, 114688, null, 0, i3.ShippingListTemplateComponent, [i4.PrintService, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_PrintComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-print", [], null, null, null, View_PrintComponent_0, RenderType_PrintComponent)), i1.ɵdid(1, 114688, null, 0, i6.PrintComponent, [i4.PrintService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrintComponentNgFactory = i1.ɵccf("app-print", i6.PrintComponent, View_PrintComponent_Host_0, {}, {}, []);
export { PrintComponentNgFactory as PrintComponentNgFactory };
