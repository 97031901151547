import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SnackBarComponent } from '../shared/snack-bar/snack-bar.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/material/snack-bar";
export class SuppliersService {
    constructor(http, snackBar) {
        this.http = http;
        this.snackBar = snackBar;
        this.curentSubject = new BehaviorSubject(null);
        this.curent$ = this.curentSubject.asObservable();
        this.labelsSubject = new BehaviorSubject([]);
        this.labels$ = this.labelsSubject.asObservable();
        this.handleError = (error) => {
            this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'right',
                data: `${error.message}`
            });
            return throwError(error);
        };
    }
    getCurrent(id) {
        const headers = new HttpHeaders({ Authorization: 'Basic ' + btoa(environment.techuser + ':' + environment.sdaSupplierApiPassword) });
        this.http.get(`${environment.sdaSupplierApiHost}/api/suppliers/supplier/${id}/labels/current`, { headers })
            .subscribe((nve) => {
            this.curentSubject.next(nve);
        }, err => {
            this.handleError(err);
        });
    }
    getLabels(id, count) {
        const headers = new HttpHeaders({ Authorization: 'Basic ' + btoa(environment.techuser + ':' + environment.sdaSupplierApiPassword) });
        return this.http.post(`${environment.sdaSupplierApiHost}/api/suppliers/supplier/${id}/labels/${count}`, null, { headers })
            .pipe(map((labels) => {
            return labels;
        }), catchError(this.handleError));
    }
    getNextShipping(id) {
        const headers = new HttpHeaders({ Authorization: 'Basic ' + btoa(environment.techuser + ':' + environment.sdaSupplierApiPassword) });
        return this.http.post(`${environment.sdaSupplierApiHost}/api/suppliers/supplier/${id}/shipping/next`, null, { headers })
            .pipe(map((shiping) => {
            return shiping;
        }), catchError(this.handleError));
    }
}
SuppliersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SuppliersService_Factory() { return new SuppliersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.MatSnackBar)); }, token: SuppliersService, providedIn: "root" });
