<div class="dialog-wrapper">
  <div class="text-center">
    <h2
      [innerHTML]="
        'copy-unit-dialog.title' | translate: { currentNve: data.currentNve }
      "
    ></h2>
    <p>
      {{ "copy-unit-dialog.next-nve" | translate }}
      <strong>{{ nextNVE }}</strong>
    </p>
    <mat-dialog-content class="content-input">
      <app-add-remove-input
        [maxNumber]="maxUnitsAllowed"
        [placeholder]="'copy-unit-dialog.unit-qty'"
        [value]="unitQuantity"
        (changeValue)="changeValueFromInput($event)"
      ></app-add-remove-input>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        [disabled]="unitQuantity === 0"
        class="mat-raised-button mat-primary mar-25"
        (click)="copyUnit()"
      >
        {{ "copy-unit-dialog.proceed" | translate }}
      </button>
      <button
        (click)="cancelCopy()"
        mat-raised-button
        class="mat-raised-button mat-primary mar-25"
      >
        {{ "copy-unit-dialog.cancel" | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
