import { Component, OnInit } from '@angular/core';
import {PrintLabelService} from '../services/print-label.service';

@Component({
  selector: 'app-printer-test',
  templateUrl: './printer-test.component.html',
  styleUrls: ['./printer-test.component.scss']
})
export class PrinterTestComponent implements OnInit {

  constructor(private printLabelService: PrintLabelService,
  ) {
  }

  ngOnInit() {

  }
  printLabel(label) {
  }
  printShipmentSummary(element: any) {

  }
}
