import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './guard/auth.guard/auth.guard.service';
import { PrinterTestComponent } from './printer-test/printer-test.component';



const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('../app/modules/splash/splash.module').then(m => m.SplashModule)
  },
  {
    path: 'overview',
    canActivate: [AuthGuard],
    loadChildren: () => import('../app/modules/purchase-order/overview.module').then(m => m.OverviewModule)
  },
  {
    path: 'nve-overview',
    canActivate: [AuthGuard],
    loadChildren: () => import('../app/modules/nve-overview/nve-overview.module').then(m => m.NVEOverviewModule)
  },
  {
    path: 'packing',
    canActivate: [AuthGuard],
    loadChildren: () => import('../app/modules/packing/packing.module').then(m => m.PackingModule)
  },
  { path: 'printer-test', component: PrinterTestComponent },
  {
    path: 'printer-configuration',
    canActivate: [AuthGuard],
    loadChildren: () => import('../app/modules/printer-configuration/printer-configuration.module').then(m => m.PrinterConfigurationModule)
  }

];



@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
