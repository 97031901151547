/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./complete-unit-actions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@angular/common";
import * as i5 from "./complete-unit-actions.component";
var styles_CompleteUnitActionsComponent = [i0.styles];
var RenderType_CompleteUnitActionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompleteUnitActionsComponent, data: {} });
export { RenderType_CompleteUnitActionsComponent as RenderType_CompleteUnitActionsComponent };
function View_CompleteUnitActionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "mat-raised-button mat-primary mar-25"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.action("copy") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("complete-unit-actions.copy-unit")); _ck(_v, 1, 0, currVal_0); }); }
function View_CompleteUnitActionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "mat-raised-button mat-primary mar-25"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.action("accessory") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("complete-unit-actions.add-accessory-unit")); _ck(_v, 1, 0, currVal_0); }); }
export function View_CompleteUnitActionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 11, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i3.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "mat-raised-button mat-primary mar-25"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.action("overview") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompleteUnitActionsComponent_1)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "mat-raised-button mat-primary mar-25"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.action("next") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompleteUnitActionsComponent_2)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.isOnline && !_co.hideCopyButton); _ck(_v, 11, 0, currVal_2); var currVal_4 = _co.showAccessoryButton; _ck(_v, 16, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("complete-unit-actions.title")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("complete-unit-actions.order-overview")); _ck(_v, 8, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("complete-unit-actions.next-unit")); _ck(_v, 13, 0, currVal_3); }); }
export function View_CompleteUnitActionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-complete-unit-actions", [], null, null, null, View_CompleteUnitActionsComponent_0, RenderType_CompleteUnitActionsComponent)), i1.ɵdid(1, 245760, null, 0, i5.CompleteUnitActionsComponent, [i3.MatDialogRef, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompleteUnitActionsComponentNgFactory = i1.ɵccf("app-complete-unit-actions", i5.CompleteUnitActionsComponent, View_CompleteUnitActionsComponent_Host_0, {}, {}, []);
export { CompleteUnitActionsComponentNgFactory as CompleteUnitActionsComponentNgFactory };
