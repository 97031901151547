export const smallLabel = `<STX>R<ETX>
<STX><ESC>C<SI>W791<SI>h<ETX>
<STX><ESC>P<ETX>
<STX>F*<ETX>
<STX>B1;f3;o231,93;c2,1;w6;h82;r0;d3,NVELABELNUMBER<ETX>
<STX>H2;f3;o150,283;c26;b0;h13;w13;d3,NVELABELCS<ETX>
<STX>D0<ETX>
<STX>R<ETX>
<STX><SI>l13<ETX>
<STX><ESC>E*,1<CAN><ETX>
<STX><RS>1<US>1<ETB><ETX>`;

export const articleLabel = `
<STX>R<ETX>
<STX><ESC>C<SI>W791<SI>h<ETX>
<STX><ESC>P<ETX>
<STX>F*<ETX>
<STX>H1;f3;o285,39;c26;b0;h17;w17;d3,Art.:<ETX>
<STX>H2;f3;o285,142;c26;b0;h17;w17;d3,ARTICLEBDNO<ETX>
<STX>H3;f3;o283,396;c26;b0;h13;w13;d3,Bestell Nr.:<ETX>
<STX>H4;f3;o283,577;c26;b0;h13;w13;d3,PURCHASEORDERNO<ETX>
<STX>H5;f3;o242,30;c26;b0;h13;w13;d3,DESIGNATION<ETX>
<STX>H6;f3;o242,582;c26;b0;h17;w17;d3,QTY UNIT<ETX>
<STX>H7;f3;o93,30;c26;b0;h13;w13;d3,Referenz Nr.:<ETX>
<STX>H8;f3;o93,225;c26;b0;h13;w13;d3,REFEREZNO<ETX>
<STX>H9;f3;o60,30;c26;b0;h13;w13;d3,AB Nummer:<ETX>
<STX>H10;f3;o60,225;c26;b0;h13;w13;d3,SUPPLIERORDER<ETX>
<STX>H11;f3;o93,398;c26;b0;h13;w13;d3,BD Auftrag:<ETX>
<STX>H12;f3;o105,595;c26;b0;h13;w13;d3,BDORDERNO<ETX>
<STX>B13;f3;o195,88;c2,1;w6;h66;r0;d3,ARTICLENO<ETX>
<STX>D0<ETX>
<STX>R<ETX>
<STX><SI>l13<ETX>
<STX><ESC>E*,1<CAN><ETX>
<STX><RS>1<US>1<ETB><ETX>
`;

export const unitLabelHeader = `<STX>R<ETX>
<STX><ESC>C<SI>W791<SI>h<ETX>
<STX><ESC>P<ETX>
<STX>F*<ETX>
<STX>U1;o135,795;f2;c1;w1;h1;<ETX>
<STX>H2;f1;o26,365;c26;b0;h17;w17;d3,Postfach 1163<ETX>
<STX>H3;f1;o64,364;c26;b0;h17;w17;d3,49377 Vechta<ETX>
<STX>H4;f1;o104,371;c26;b0;h17;w17;d3,big@bigdutchman.de<ETX>
<STX>L5;f0;o159,10;l274;w3<ETX>
<STX>L6;f1;o430,788;l775;w3<ETX>
<STX>L7;f0;o159,788;l274;w3<ETX>
<STX>L8;f1;o159,788;l775;w3<ETX>
<STX>H9;f1;o175,780;c26;b0;h23;w23;d3,Lieferant:<ETX>
<STX>H10;f1;o236,780;c26;b0;h23;w23;d3,Bestellung:<ETX>
<STX>H11;f1;o309,780;c26;b1;h23;w24;d3,Einheit Nr:        NVENUMBER<ETX>
<STX>H12;f1;o366,780;c26;b0;h23;w23;d3,Auftrag:<ETX>
<STX>B13;f1;o455,655;c2,1;w6;h274;r0;d3,NVELABEL<ETX>
<STX>H14;f1;o726,505;c26;b0;h20;w20;d3,NVECKS<ETX>
<STX>H15;f1;o799,782;c26;b0;h17;w17;d3,PACKINGTYPE<ETX>
<STX>H16;f1;o843,782;c26;b0;h17;w17;d3,L\\ B\\ H: UNITLENGTH cm \\ UNITWIDTH cm \\ UNITHEIGHT cm - UNITWEIGHTkg<ETX>
<STX>L17;f0;o793,10;l51;w3<ETX>
<STX>L18;f1;o841,788;l775;w3<ETX>
<STX>L19;f0;o793,788;l51;w3<ETX>
<STX>L20;f1;o793,788;l775;w3<ETX>
<STX>L21;f0;o841,10;l51;w3<ETX>
<STX>L22;f1;o889,788;l775;w3<ETX>
<STX>L23;f0;o841,788;l51;w3<ETX>
<STX>L24;f1;o841,788;l775;w3<ETX>
<STX>H25;f1;o901,780;c26;b0;h17;w17;d3,Menge<ETX>
<STX>H26;f1;o901,626;c26;b0;h17;w17;d3,ArtikelNr<ETX>
<STX>H27;f1;o901,411;c26;b0;h17;w17;d3,Bezeichnung<ETX>`;

export const hellmanUnitLabelHeader = `<STX>R<ETX>
<STX><ESC>C<SI>W791<SI>h<ETX>
<STX><ESC>P<ETX>
<STX>F*<ETX>
<STX>H45;f1;o25,793;c26;b0;h20;w20;d3,Hellmann Poultry<ETX>
<STX>H46;f1;o87,793;c26;b0;h20;w20;d3,GmbH & Co. KG<ETX>
<STX>H1;f1;o23,363;c26;b0;h17;w17;d3,Hellms Damm 4<ETX>
<STX>H2;f1;o64,363;c26;b0;h17;w17;d3,49377 Vechta<ETX>
<STX>H3;f1;o104,363;c26;b0;h17;w17;d3,Deutschland<ETX>
<STX>L5;f0;o159,10;l274;w3<ETX>
<STX>L6;f1;o430,788;l775;w3<ETX>
<STX>L7;f0;o159,788;l274;w3<ETX>
<STX>L8;f1;o159,788;l775;w3<ETX>
<STX>H9;f1;o175,780;c26;b0;h23;w23;d3,Lieferant:<ETX>
<STX>H10;f1;o236,780;c26;b0;h23;w23;d3,Bestellung:<ETX>
<STX>H11;f1;o309,780;c26;b1;h23;w24;d3,Einheit Nr:        NVENUMBER<ETX>
<STX>H12;f1;o366,780;c26;b0;h23;w23;d3,Auftrag:<ETX>
<STX>B13;f1;o455,655;c2,1;w6;h274;r0;d3,NVELABEL<ETX>
<STX>H14;f1;o726,505;c26;b0;h20;w20;d3,NVECKS<ETX>
<STX>H15;f1;o799,782;c26;b0;h17;w17;d3,PACKINGTYPE<ETX>
<STX>H16;f1;o843,782;c26;b0;h17;w17;d3,L\\ B\\ H: UNITLENGTH cm \\ UNITWIDTH cm \\ UNITHEIGHT cm - UNITWEIGHTkg<ETX>
<STX>L17;f0;o793,10;l51;w3<ETX>
<STX>L18;f1;o841,788;l775;w3<ETX>
<STX>L19;f0;o793,788;l51;w3<ETX>
<STX>L20;f1;o793,788;l775;w3<ETX>
<STX>L21;f0;o841,10;l51;w3<ETX>
<STX>L22;f1;o889,788;l775;w3<ETX>
<STX>L23;f0;o841,788;l51;w3<ETX>
<STX>L24;f1;o841,788;l775;w3<ETX>
<STX>H25;f1;o901,780;c26;b0;h17;w17;d3,Menge<ETX>
<STX>H26;f1;o901,626;c26;b0;h17;w17;d3,ArtikelNr<ETX>
<STX>H27;f1;o901,411;c26;b0;h17;w17;d3,Bezeichnung<ETX>`;

export const powerSystemUnitLabelHeader = `<STX>R<ETX>
<STX><ESC>C<SI>W791<SI>h<ETX>
<STX><ESC>P<ETX>
<STX>F*<ETX>
<STX>U1;o135,795;f2;c1;w1;h1;<ETX>
<STX>H2;f1;o26,365;c26;b0;h17;w17;d3,Konrad-Zuse-Straße 1<ETX>
<STX>H3;f1;o64,364;c26;b0;h17;w17;d3,49456 Bakum <ETX>
<STX>H4;f1;o104,371;c26;b0;h17;w17;d3,Deutschland<ETX>
<STX>L5;f0;o159,10;l274;w3<ETX>
<STX>L6;f1;o430,788;l775;w3<ETX>
<STX>L7;f0;o159,788;l274;w3<ETX>
<STX>L8;f1;o159,788;l775;w3<ETX>
<STX>H9;f1;o175,780;c26;b0;h23;w23;d3,Lieferant:<ETX>
<STX>H10;f1;o236,780;c26;b0;h23;w23;d3,Bestellung:<ETX>
<STX>H11;f1;o309,780;c26;b1;h23;w24;d3,Einheit Nr:        NVENUMBER<ETX>
<STX>H12;f1;o366,780;c26;b0;h23;w23;d3,Auftrag:<ETX>
<STX>B13;f1;o455,655;c2,1;w6;h274;r0;d3,NVELABEL<ETX>
<STX>H14;f1;o726,505;c26;b0;h20;w20;d3,NVECKS<ETX>
<STX>H15;f1;o799,782;c26;b0;h17;w17;d3,PACKINGTYPE<ETX>
<STX>H16;f1;o843,782;c26;b0;h17;w17;d3,L\\ B\\ H: UNITLENGTH cm \\ UNITWIDTH cm \\ UNITHEIGHT cm - UNITWEIGHTkg<ETX>
<STX>L17;f0;o793,10;l51;w3<ETX>
<STX>L18;f1;o841,788;l775;w3<ETX>
<STX>L19;f0;o793,788;l51;w3<ETX>
<STX>L20;f1;o793,788;l775;w3<ETX>
<STX>L21;f0;o841,10;l51;w3<ETX>
<STX>L22;f1;o889,788;l775;w3<ETX>
<STX>L23;f0;o841,788;l51;w3<ETX>
<STX>L24;f1;o841,788;l775;w3<ETX>
<STX>H25;f1;o901,780;c26;b0;h17;w17;d3,Menge<ETX>
<STX>H26;f1;o901,626;c26;b0;h17;w17;d3,ArtikelNr<ETX>
<STX>H27;f1;o901,411;c26;b0;h17;w17;d3,Bezeichnung<ETX>`;

export const unitLabelBody = `
<STX>HUNITBODYFIRST;f1;oLABELLINE,780;c26;b0;h17;w17;d3,ASSIQTY UNITQTY<ETX>
<STX>HUNITBODYSECOND;f1;oLABELLINE,626;c26;b1;h17;w17;d3,ASSIARTBDNO<ETX>
<STX>HUNITBODYTHIRD;f1;oLABELLINE,411;c26;b0;h17;w17;d3,ASSIDESBD<ETX>`;

export const unitLabelFooter = `
<STX>HUNITFOOTERFIRST;f1;o228,291;c26;b0;h23;w23;d3,BDPURCHASEORDERNO<ETX>
<STX>HUNITFOOTERSECOND;f1;o175,293;c26;b0;h23;w23;d3,SUPLIERID<ETX>
<STX>HUNITFOOTERTHIRD;f1;o366,291;c26;b0;h23;w23;d3,BDORDERNO<ETX>
<STX>D0<ETX>
<STX>R<ETX>
<STX><SI>l13<ETX>
<STX><ESC>E*,1<CAN><ETX>
<STX><RS>1<US>1<ETB><ETX>`;


export const contentLabelHeader = `
<STX>R<ETX>
<STX><ESC>C<SI>W791<SI>h<ETX>
<STX><ESC>P<ETX>
<STX>F*<ETX>
<STX>U1;o135,795;f2;c1;w1;h1;<ETX>
<STX>H2;f1;o20,369;c26;b0;h17;w17;d3,Postfach 1163<ETX>
<STX>H3;f1;o63,369;c26;b0;h17;w17;d3,49377 Vechta<ETX>
<STX>H4;f1;o112,369;c26;b0;h17;w17;d3,big@bigdutchman.de<ETX>
<STX>L5;f0;o159,2;l162;w3<ETX>
<STX>L6;f1;o318,781;l776;w3<ETX>
<STX>L7;f0;o159,781;l162;w3<ETX>
<STX>L8;f1;o159,781;l776;w3<ETX>
<STX>H9;f1;o177,775;c26;b0;h17;w17;d3,Lieferant:SUPPLIERID<ETX>
<STX>H10;f1;o177,369;c26;b0;h17;w17;d3,Bestellung:BDPURCHASEORDERNO<ETX>
<STX>H11;f1;o228,775;c26;b0;h17;w17;d3,Einheit Nr:NVENUMBER<ETX>
<STX>H12;f1;o268,775;c26;b0;h17;w17;d3,Auftrag:BDORDERNUMBER<ETX>
<STX>H60;f1;o268,309;c26;b0;h17;w17;d3,Etikett 2 von 2<ETX>
<STX>H13;f1;o318,748;c26;b0;h17;w17;d3,Menge<ETX>
<STX>H14;f1;o318,613;c26;b0;h17;w17;d3,ArtikelNr<ETX>
<STX>H15;f1;o318,403;c26;b0;h17;w17;d3,Bezeichnung<ETX>`;

export const hellmanContentLabelHeader = `
<STX>R<ETX>
<STX><ESC>C<SI>W791<SI>h<ETX>
<STX><ESC>P<ETX>
<STX>F*<ETX>
<STX>H45;f1;o25,793;c26;b0;h20;w20;d3,Hellmann Poultry<ETX>
<STX>H46;f1;o87,793;c26;b0;h20;w20;d3,GmbH & Co. KG<ETX>
<STX>H1;f1;o23,363;c26;b0;h17;w17;d3,Hellms Damm 4<ETX>
<STX>H2;f1;o64,363;c26;b0;h17;w17;d3,49377 Vechta<ETX>
<STX>H3;f1;o104,363;c26;b0;h17;w17;d3,Deutschland<ETX>
<STX>L5;f0;o159,2;l162;w3<ETX>
<STX>L6;f1;o318,781;l776;w3<ETX>
<STX>L7;f0;o159,781;l162;w3<ETX>
<STX>L8;f1;o159,781;l776;w3<ETX>
<STX>H9;f1;o177,775;c26;b0;h17;w17;d3,Lieferant:SUPPLIERID<ETX>
<STX>H10;f1;o177,369;c26;b0;h17;w17;d3,Bestellung:BDPURCHASEORDERNO<ETX>
<STX>H11;f1;o228,775;c26;b0;h17;w17;d3,Einheit Nr:NVENUMBER<ETX>
<STX>H12;f1;o268,775;c26;b0;h17;w17;d3,Auftrag:BDORDERNUMBER<ETX>
<STX>H60;f1;o268,309;c26;b0;h17;w17;d3,Etikett 2 von 2<ETX>
<STX>H13;f1;o318,748;c26;b0;h17;w17;d3,Menge<ETX>
<STX>H14;f1;o318,613;c26;b0;h17;w17;d3,ArtikelNr<ETX>
<STX>H15;f1;o318,403;c26;b0;h17;w17;d3,Bezeichnung<ETX>`;

export const powerSystemContentLabelHeader = `
<STX>R<ETX>
<STX><ESC>C<SI>W791<SI>h<ETX>
<STX><ESC>P<ETX>
<STX>F*<ETX>
<STX>U1;o135,795;f2;c1;w1;h1;<ETX>
<STX>H2;f1;o20,369;c26;b0;h17;w17;d3,Konrad-Zuse-Straße 1<ETX>
<STX>H3;f1;o63,369;c26;b0;h17;w17;d3,49456 Bakum<ETX>
<STX>H4;f1;o112,369;c26;b0;h17;w17;d3,Deutschland<ETX>
<STX>L5;f0;o159,2;l162;w3<ETX>
<STX>L6;f1;o318,781;l776;w3<ETX>
<STX>L7;f0;o159,781;l162;w3<ETX>
<STX>L8;f1;o159,781;l776;w3<ETX>
<STX>H9;f1;o177,775;c26;b0;h17;w17;d3,Lieferant:SUPPLIERID<ETX>
<STX>H10;f1;o177,369;c26;b0;h17;w17;d3,Bestellung:BDPURCHASEORDERNO<ETX>
<STX>H11;f1;o228,775;c26;b0;h17;w17;d3,Einheit Nr:NVENUMBER<ETX>
<STX>H12;f1;o268,775;c26;b0;h17;w17;d3,Auftrag:BDORDERNUMBER<ETX>
<STX>H60;f1;o268,309;c26;b0;h17;w17;d3,Etikett 2 von 2<ETX>
<STX>H13;f1;o318,748;c26;b0;h17;w17;d3,Menge<ETX>
<STX>H14;f1;o318,613;c26;b0;h17;w17;d3,ArtikelNr<ETX>
<STX>H15;f1;o318,403;c26;b0;h17;w17;d3,Bezeichnung<ETX>`;

export const contentLabelBody = `
<STX>HUNITBODYFIRST;f1;oLABELLINE,789;c26;b0;h17;w17;d3,ASSIQTY UNITQTY<ETX>
<STX>HUNITBODYSECOND;f1;oLABELLINE,622;c26;b1;h17;w17;d3,ASSIARTBDNUMBER<ETX>
<STX>HUNITBODYTHIRD;f1;oLABELLINE,403;c26;b0;h17;w17;d3,ASSIDESBD<ETX>`;

export const contentLabelFooter = `
<STX>R<ETX>
<STX><SI>l13<ETX>
<STX><ESC>E*,1<CAN><ETX>
<STX><RS>1<US>1<ETB><ETX>`;

export const testBigLabel = `
<STX>R<ETX>
<STX><ESC>C<SI>W791<SI>h<ETX>
<STX><ESC>P<ETX>
<STX>F*<ETX>
<STX>U1;o135,795;f2;c1;w1;h1;<ETX>
<STX>H2;f1;o26,331;c26;b0;h13;w13;d3,Postfach 1163<ETX>
<STX>H3;f1;o68,327;c26;b0;h13;w13;d3,49377 Vechta<ETX>
<STX>H4;f1;o112,327;c26;b0;h13;w13;d3,big@bigdutchman.de<ETX>
<STX>L5;f0;o159,84;l274;w3<ETX>
<STX>L6;f1;o430,742;l655;w3<ETX>
<STX>L7;f0;o159,742;l274;w3<ETX>
<STX>L8;f1;o159,742;l655;w3<ETX>
<STX>H9;f1;o176,736;c26;b0;h20;w20;d3,Lieferant:<ETX>
<STX>H10;f1;o244,736;c26;b0;h20;w20;d3,Bestellung:<ETX>
<STX>H11;f1;o310,736;c26;b1;h20;w21;d3,Einheit Nr:     719000000<ETX>
<STX>H12;f1;o367,736;c26;b0;h20;w20;d3,Auftrag:<ETX>
<STX>B13;f1;o455,680;c2,1;w6;h166;r0;d3,719003244<ETX>
<STX>H14;f1;o618,529;c26;b0;h20;w20;d3,7190032446<ETX>
<STX>H15;f1;o693,736;c26;b0;h13;w13;d3,1 Rahmenpal.<ETX>
<STX>H16;f1;o742,759;c26;b0;h13;w13;d3,L\\ B\\ H: 120 cm \\ 80 cm\\ 35 cm - 400kg<ETX>
<STX>L17;f0;o687,85;l52;w3<ETX>
<STX>L18;f1;o736,742;l654;w3<ETX>
<STX>L19;f0;o687,742;l52;w3<ETX>
<STX>L20;f1;o687,742;l654;w3<ETX>
<STX>L21;f0;o736,85;l51;w3<ETX>
<STX>L22;f1;o784,742;l654;w3<ETX>
<STX>L23;f0;o736,742;l51;w3<ETX>
<STX>L24;f1;o736,742;l654;w3<ETX>
<STX>H25;f1;o795,745;c26;b0;h17;w17;d3,Menge<ETX>
<STX>H26;f1;o795,615;c26;b0;h17;w17;d3,ArtikelNr<ETX>
<STX>H27;f1;o795,420;c26;b0;h17;w17;d3,Bezeichnung<ETX>
<STX>H28;f1;o844,745;c26;b0;h13;w13;d3,18 ST<ETX>
<STX>H29;f1;o844,615;c26;b1;h13;w13;d3,39-00-4747<ETX>
<STX>H30;f1;o844,420;c26;b0;h13;w13;d3,Umlenkrolle für Schr<ETX>
<STX>H31;f1;o884,745;c26;b0;h13;w13;d3,18 ST<ETX>
<STX>H32;f1;o884,615;c26;b1;h13;w13;d3,39-00-4747<ETX>
<STX>H33;f1;o884,420;c26;b0;h13;w13;d3,Umlenkrolle für Schr<ETX>
<STX>H34;f1;o927,745;c26;b0;h13;w13;d3,18 ST<ETX>
<STX>H35;f1;o924,615;c26;b1;h13;w13;d3,39-00-4747<ETX>
<STX>H36;f1;o924,420;c26;b0;h13;w13;d3,Umlenkrolle für Schr<ETX>
<STX>H37;f1;o964,745;c26;b0;h13;w13;d3,18 ST<ETX>
<STX>H38;f1;o964,615;c26;b1;h13;w13;d3,39-00-4747<ETX>
<STX>H39;f1;o964,420;c26;b0;h13;w13;d3,Umlenkrolle für Schr<ETX>
<STX>H40;f1;o1004,745;c26;b0;h13;w13;d3,18 ST<ETX>\t\t
<STX>H41;f1;o1004,615;c26;b1;h13;w13;d3,39-00-4747<ETX>
<STX>H42;f1;o1004,420;c26;b0;h13;w13;d3,Umlenkrolle für Schr<ETX>
<STX>H43;f1;o244,360;c26;b0;h20;w20;d3,3796216<ETX>
<STX>H44;f1;o176,358;c26;b0;h20;w20;d3,770518<ETX>
<STX>H45;f1;o367,358;c26;b0;h20;w20;d3,1558136<ETX>
<STX>D0<ETX>
<STX>R<ETX>
<STX><SI>l13<ETX>
<STX><ESC>E*,1<CAN><ETX>
<STX><RS>1<US>1<ETB><ETX>
`;

export const testSmallLabel = `<STX>R<ETX>
<STX><ESC>C<SI>W791<SI>h<ETX>
<STX><ESC>P<ETX>
<STX>F*<ETX>
<STX>H1;f3;o285,39;c26;b0;h17;w17;d3,Art.:<ETX>
<STX>H2;f3;o285,142;c26;b0;h17;w17;d3,999101045 BD<ETX>
<STX>H3;f3;o283,396;c26;b0;h13;w13;d3,Bestell Nr.:<ETX>
<STX>H4;f3;o283,577;c26;b0;h13;w13;d3,3456789<ETX>
<STX>H5;f3;o242,30;c26;b0;h13;w13;d3,Skt-Mutter M 6 verz DIN934-8<ETX>
<STX>H6;f3;o242,582;c26;b0;h17;w17;d3,50 ST<ETX>
<STX>H7;f3;o93,30;c26;b0;h13;w13;d3,Referenz Nr.:<ETX>
<STX>H8;f3;o93,225;c26;b0;h13;w13;d3,471123456<ETX>
<STX>H9;f3;o60,30;c26;b0;h13;w13;d3,AB Nummer:<ETX>
<STX>H10;f3;o60,225;c26;b0;h13;w13;d3,23412344<ETX>
<STX>H11;f3;o93,398;c26;b0;h13;w13;d3,BD Auftrag:<ETX>
<STX>H12;f3;o105,595;c26;b0;h13;w13;d3,1434567<ETX>
<STX>D0<ETX>
<STX>R<ETX>
<STX><SI>l13<ETX>
<STX><ESC>E*,1<CAN><ETX>
<STX><RS>1<US>1<ETB><ETX>`;
