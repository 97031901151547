<div>
  <div class="text-center">
    <h2>{{'complete-unit-actions.title' | translate }}</h2>
  </div>

  <mat-dialog-actions>
    <button
      class="mat-raised-button mat-primary mar-25"
      (click)="action('overview')"
    >
      {{'complete-unit-actions.order-overview' | translate }}
    </button>
    <button
      *ngIf="isOnline && !hideCopyButton"
      class="mat-raised-button mat-primary mar-25"
      (click)="action('copy')"
    >
      {{'complete-unit-actions.copy-unit' | translate }}
    </button>
    <button
      class="mat-raised-button mat-primary mar-25"
      (click)="action('next')"
    >
      {{'complete-unit-actions.next-unit' | translate }}
    </button>
    <button
      *ngIf="showAccessoryButton"
      class="mat-raised-button mat-primary mar-25"
      (click)="action('accessory')"
    >
      {{ 'complete-unit-actions.add-accessory-unit' | translate }}
    </button>
  </mat-dialog-actions>
</div>
