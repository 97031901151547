import { Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard/auth.guard.service';
import { PrinterTestComponent } from './printer-test/printer-test.component';
const ɵ0 = () => import("./modules/splash/splash.module.ngfactory").then(m => m.SplashModuleNgFactory), ɵ1 = () => import("./modules/purchase-order/overview.module.ngfactory").then(m => m.OverviewModuleNgFactory), ɵ2 = () => import("./modules/nve-overview/nve-overview.module.ngfactory").then(m => m.NVEOverviewModuleNgFactory), ɵ3 = () => import("./modules/packing/packing.module.ngfactory").then(m => m.PackingModuleNgFactory), ɵ4 = () => import("./modules/printer-configuration/printer-configuration.module.ngfactory").then(m => m.PrinterConfigurationModuleNgFactory);
const routes = [
    {
        path: '',
        loadChildren: ɵ0
    },
    {
        path: 'overview',
        canActivate: [AuthGuard],
        loadChildren: ɵ1
    },
    {
        path: 'nve-overview',
        canActivate: [AuthGuard],
        loadChildren: ɵ2
    },
    {
        path: 'packing',
        canActivate: [AuthGuard],
        loadChildren: ɵ3
    },
    { path: 'printer-test', component: PrinterTestComponent },
    {
        path: 'printer-configuration',
        canActivate: [AuthGuard],
        loadChildren: ɵ4
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
