<div>
  <button (click)="closeDialog()" class="close-btn" mat-large mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <div class="dialog-wrapper scanner">
    <div class="text-center">
      <h2>{{ "shipping-dialog.title" | translate }}</h2>
    </div>

    <div *ngIf="scannerMode">
      <mat-dialog-content class="packing">
        <div
          *ngIf="showStatus === 'scanner-start'"
          class="scanner-icon text-center"
        >
          <h3>{{ "shipping-dialog.scan-bar-code" | translate }}</h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Ebene_1"
            data-name="Ebene 1"
            viewBox="0 0 496 496"
          >
            <title>barcode-scanner</title>
            <path
              d="M377,269.89H321v-8a8,8,0,0,0-6.06-7.76l-64-16a8,8,0,0,0-9.89,8.61l24,224a8,8,0,0,0,7.95,7.15h40a8,8,0,0,0,8-8v-8h64v40a8,8,0,0,0,8,8h8v48h-8a8,8,0,0,0-8,8v64a40,40,0,0,0,40,40h80a8,8,0,0,0,8-8v-200h8a24,24,0,0,0,24-24c0-92.64-75.36-168-168-168Zm-72,192H280.18l-22-205.47L305,268.14Zm112,48h16v48H417Zm80,144H425a24,24,0,0,1-24-24v-56h40a8,8,0,0,0,8-8v-64a8,8,0,0,0-8-8H401v-32h96Zm24-208H321v-160h56c83.82,0,152,68.18,152,152a8,8,0,0,1-8,8Zm0,0"
              transform="translate(-49 -173.89)"
            />
            <rect x="352" y="176" width="80" height="16" />
            <rect x="352" y="208" width="80" height="16" />
            <rect x="352" y="240" width="80" height="16" />
            <path
              d="M217,173.89H57a8,8,0,0,0-8,8v336a8,8,0,0,0,8,8H217a8,8,0,0,0,8-8v-336a8,8,0,0,0-8-8Zm-8,336H65v-320H209Zm0,0"
              transform="translate(-49 -173.89)"
            />
            <rect x="32" y="224" width="112" height="16" />
            <rect x="32" y="304" width="112" height="16" />
            <rect x="32" y="192" width="112" height="16" />
            <rect x="32" y="112" width="112" height="16" />
            <rect x="32" y="32" width="112" height="16" />
            <rect x="32" y="256" width="112" height="32" />
            <rect x="32" y="144" width="112" height="32" />
            <rect x="32" y="64" width="112" height="32" />
          </svg>
        </div>
        <div
          *ngIf="showStatus === 'scanner-progress'"
          class="scanner-icon text-center"
        >
          <div class="camera">
            <barcode-scanner-livestream
              type="i2of5"
              (valueChanges)="onValueChanges($event)"
            ></barcode-scanner-livestream>
          </div>
        </div>
        <div *ngIf="showStatus === 'scanner-finish'">
          <h3 class="text-center">{{ "shipping-dialog.nve-number" | translate }}:</h3>
          <h3 class="text-center">
            <strong>{{ barcodeNumber }}</strong>
          </h3>
          <div *ngIf="msg != 'checking' ">
            <p class="text-center">{{ msg }}</p>
          </div>
          <div
            style="position: absolute;
            bottom: 15px;
            width: 100%;"
          >
            <button
              [disabled]="msg != ''"
              class="mat-stroked-button mat-primary"
              (click)="submitBarcode()"
            >
              {{ "shipping-dialog.submit" | translate }}
            </button>
            <button
              [disabled]="msg == 'checking' || msg == ''"
              class="mat-stroked-button mat-primary"
              (click)="scanAgain()"
            >
              {{ "shipping-dialog.scan" | translate }}
            </button>
          </div>
        </div>
      </mat-dialog-content>
    </div>

 <!--   <mat-dialog-content *ngIf="!scannerMode">
      <div *ngIf="msg !== ''">
        <h3 class="text-center">{{ "shipping-dialog.nve-number" | translate }}:</h3>
        <h3 class="text-center">
          <strong>{{ inputValue }}</strong>
        </h3>
        <div *ngIf="msg != 'checking' ">
          <p class="text-center">{{ msg }}</p>
        </div>
      </div>
      <mat-grid-list cols="6" rowHeight="80px">
        <mat-grid-tile [colspan]="4">
          <mat-form-field class="my-form-field" style="width: 90%;">
            <input
              matInput
              type="number"
              #inputField
              cdkFocusInitial
              id="myInput"
              [(ngModel)]="inputValue"
              (keyup)="clearMsg()"
              (keyup.enter)="submit()"
              placeholder="{{
                isArticle
                  ? ('shipping-dialog.article-number' | translate)
                  : ('shipping-dialog.nve-number' | translate)
              }}"
            />
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2">
          <button (click)="submit()" class="text-center" mat-stroked-button>
            {{ "shipping-dialog.submit" | translate }}
          </button>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-dialog-content>-->
  <!--  <div class="toggle-button">
      <mat-slide-toggle [checked]="scannerMode" (change)="startScanner()">{{
        "shipping-dialog.scanner-mode" | translate
      }}</mat-slide-toggle>
    </div>-->
  </div>
</div>
