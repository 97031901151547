import { Component, OnInit, ViewChild, OnDestroy, ElementRef, Output, ViewEncapsulation, NgZone  } from '@angular/core';
import { PrintService } from 'src/app/services/print.service';
import { Previewer } from 'pagedjs';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-shipping-list-template',
  templateUrl: './shipping-list-template.component.html',
  styleUrls: ['./shipping-list-template.component.scss']
})
export class ShippingListTemplateComponent implements OnInit {

  now;
  shipingList;
  constructor(private printService: PrintService,    private translate: TranslateService) { }

  ngOnInit() {
    this.printService.changes.subscribe(data => {
      this.now = new Date();
      this.shipingList = { ...data };
      this.shipingList.data.sort((a,b) => a.purchaseOrder.bdPurchaseOrderNumber.toString().localeCompare(b.purchaseOrder.bdPurchaseOrderNumber.toString()));
      const paged = new Previewer();
      console.log(paged);
      console.log('--------------------------------------------', this.shipingList);
    });
  }


}
