import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';


@Component({
  selector: 'app-nve-barcode-dialog',
  templateUrl: './nve-barcode-dialog.component.html',
  styleUrls: ['./nve-barcode-dialog.component.scss']
})
export class NveBarcodeDialogComponent implements OnInit, OnDestroy {


  currentNumber;
  user;
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  qty = 1;
  constructor(
    private suppliersService: SuppliersService,
    private dialogRef: MatDialogRef<NveBarcodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) {

  }

  changeValueFromInput(val) {
    this.qty = +val;
  }

  ngOnInit() {
    // TODO get supplier id
    this.user = JSON.parse(localStorage.getItem('currentUser'));

    if (this.user) {
     // this.suppliersService.getCurrent(this.user.supplierId);

    }

    // this.suppliersService.curent$
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(current => {
    //     this.currentNumber = current;
    //   });

  }

  printNVEBarcode() {
    // TODO get supplier id
    this.suppliersService.getLabels(this.user.supplierId, this.qty).pipe(takeUntil(this.destroy$))
      .subscribe(labels => {
        console.log(labels);
        if (labels.length > 0) {
          this.dialogRef.close(labels);
        }
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

}
