<div class="dialog-wrapper">
  <button (click)="close()" class="close-btn" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <div class="text-center">
    <h2>{{'article-label-dialog.title' | translate }}</h2>
    <h3 [innerHTML]="'article-label-dialog.subtitle' | translate:{articleQtyOrdered: article.articleQtyOrdered}"></h3>
  </div>

  <mat-dialog-content class="content-input">
    <app-add-remove-input
      maxNumber="article.articleQtyOrdered"
      [placeholder]="'article-label-dialog.number-of-article'"
      [value]="printLabel.numberOfArticles"
      (changeValue)="changeValueFromInput($event, 'numberOfArticles')"
    ></app-add-remove-input>
  </mat-dialog-content>
  <mat-dialog-content class="content-input">
    <app-add-remove-input
      [placeholder]="'article-label-dialog.label-quantity'"
      [value]="printLabel.labelQuantity"
      (changeValue)="changeValueFromInput($event, 'labelQuantity')"
    ></app-add-remove-input>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      [ngClass]="{
        disabled:
          printLabel.labelQuantity === 0 ||
          printLabel.labelQuantity === null ||
          printLabel.numberOfArticles === 0 ||
          printLabel.numberOfArticles === null
      }"
      [disabled]="
        printLabel.labelQuantity === 0 ||
        printLabel.labelQuantity === null ||
        printLabel.numberOfArticles === 0 ||
        printLabel.numberOfArticles === null
      "
      class="mat-raised-button mat-primary"
      (click)="save()"
    >
    {{'article-label-dialog.print' | translate }}
    </button>
  </mat-dialog-actions>
</div>
