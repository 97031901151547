import {
  contentLabelHeader,
  hellmanContentLabelHeader,
  hellmanUnitLabelHeader, powerSystemContentLabelHeader,
  powerSystemUnitLabelHeader,
  unitLabelHeader
} from "./labels";

export const companyUnitLabelHeaders = {
  'Hellman': {
    'IPL': hellmanUnitLabelHeader,
    'ZPL': 'Hellman'
  },
  'BD': {
    'IPL': unitLabelHeader,
    'ZPL': 'BD'
  },
  'BD PowerSystems': {
    'IPL': powerSystemUnitLabelHeader,
    'ZPL': 'BD PowerSystems'
  }
};

export const companyContentLabelHeaders = {
  'Hellman': {
    'IPL': hellmanContentLabelHeader,
    'ZPL': 'Hellman'
  },
  'BD': {
    'IPL': contentLabelHeader,
    'ZPL': 'BD'
  },
  'BD PowerSystems': {
    'IPL': powerSystemContentLabelHeader,
    'ZPL': 'BD PowerSystems'
  }
};
