import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import * as i0 from "@angular/core";
PouchDB.plugin(PouchDBFind);
export class NvesService {
    constructor() {
        this.dbDataSubject = new BehaviorSubject(null);
        this.dbDataInLoopSubject = new BehaviorSubject(null);
        this.nveInLoop$ = this.dbDataInLoopSubject.asObservable();
        this.nve$ = this.dbDataSubject.asObservable();
        this.listener = new EventEmitter();
        if (!this.isInstantiated) {
            console.info('initialize local db ...');
            this.database = new PouchDB('nves');
            this.isInstantiated = true;
        }
    }
    fetch() {
        return this.database.allDocs({ include_docs: true });
    }
    get(id) {
        return this.database.get(id);
    }
    createNve(nve) {
        console.log('creatingNve', nve);
        return this.database.post(nve).then(response => {
            if (response.ok) {
                return this.database.get(response.id).then((doc) => {
                    console.log(doc);
                    return doc;
                });
            }
        }, error => {
            if (error.status === '404') {
                console.log('createNve', error);
                return null;
            }
            else {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
        });
    }
    updateNve(nve, isInLoop) {
        console.log('updatingNve', nve);
        this.database.put(nve).then(response => {
            if (response.ok) {
                this.database.get(response.id).then(doc => {
                    console.log('updateNve', doc);
                    if (isInLoop) {
                        this.dbDataInLoopSubject.next(doc);
                    }
                    else {
                        this.dbDataSubject.next(doc);
                    }
                });
            }
        }, error => {
            if (error.status === '404') {
                console.log('updateNve', error);
                return null;
            }
            else {
                return new Promise((resolve, reject) => {
                    console.log('updateNve', reject);
                    reject(error);
                });
            }
        });
    }
    put(id, document) {
        document._id = id;
        return this.get(id).then(result => {
            document._rev = result._rev;
            return this.database.put(document);
        }, error => {
            if (error.status === '404') {
                return this.database.put(document);
            }
            else {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
        });
    }
    sync(remote, supplierId) {
        console.info('sync with remote ');
        if (!this.remoteDatabase) {
            this.remoteDatabase = new PouchDB(remote);
            this.database.sync(this.remoteDatabase, {
                live: true,
                retry: true,
                filter: 'filterBySupplierId/filterBySupplierId',
                query_params: { "supplierId": supplierId }
            }).on('change', change => {
                console.info('... change event ', change);
                this.listener.emit(change);
            }).on('paused', paused => {
                // replication paused
                console.info('... paused event ', paused);
            }).on('active', active => {
                // replication resumed
                console.info('... active event ', active);
            }).on('denied', denied => {
                // replication failed .. permissions?
                console.warn(JSON.stringify(denied));
            }).on('complete', complete => {
                // replication complete
                console.info(JSON.stringify(complete));
            }).on('error', error => {
                console.error(JSON.stringify(error));
            });
        }
    }
    getChangeListener() {
        return this.listener;
    }
    fetchNotClosed() {
        return this.database.find({
            selector: { processStatus: { $ne: 6 } },
            limit: 100
        });
    }
    findByNveNumber(nveNumber) {
        return this.database.find({
            // always convert to string
            selector: { nveNumber: { $eq: "" + nveNumber } }
        });
    }
    findByPurchaseOrderNumber(bdPurchaseOrderNumber) {
        return this.database.find({
            selector: { bdPurchaseOrderNumber: { $eq: bdPurchaseOrderNumber } }
        });
    }
    filterData(filter) {
        console.log('nve filterData', filter);
        return this.database.find({
            selector: {
                $or: [
                    { nveNumber: { $regex: RegExp(filter.searchFilter, 'i') } },
                    { supplierABNumber: { $regex: RegExp(filter.searchFilter, 'i') } },
                    { bdPurchaseOrderNumber: { $regex: RegExp(filter.searchFilter, 'i') } },
                    { bdOrderNumber: { $regex: RegExp(filter.searchFilter, 'i') } },
                    {
                        assignedPurchaseOrderItems: {
                            $elemMatch: {
                                bdArticleNumber: { $regex: RegExp(filter.searchFilter, 'i') },
                            }
                        }
                    },
                    {
                        assignedPurchaseOrderItems: {
                            $elemMatch: {
                                supplierArticleNumber: { $regex: RegExp(filter.searchFilter, 'i') },
                            }
                        }
                    }
                ]
            }
        });
    }
    filterByNveNumberAndDeliveryAddress(nveNumber, address) {
        return this.database.find({
            selector: {
                $and: [
                    { deliveryAddressNumber: { $eq: address } },
                    { nveNumber: { $eq: nveNumber } },
                ]
            }
        });
    }
    filterByDeliveryType(deliveryType) {
        return this.database.find({
            selector: { deliveryType: { $eq: deliveryType } }
        });
    }
    filterByDeliveryAddress(address) {
        console.log('in nves service', address);
        //if it is Hellmann Poultry GmbH & Co. KG old and new address SDA-426
        if (address === '485088' || address === '613862') {
            return this.database.find({
                selector: {
                    deliveryAddressNumber: { $in: ['485088', '613862'] }
                }
            });
        }
        else {
            return this.database.find({
                selector: {
                    $or: [
                        { deliveryAddressNumber: { $regex: RegExp(address, 'i') } },
                    ]
                }
            });
        }
    }
    deleteNve(nve) {
        nve._deleted = true;
        return this.database.put(nve).then(res => {
            if (res) {
                return res;
            }
        });
    }
    multiCreateNve(m) {
        return this.database.bulkDocs(m).then((response) => {
            console.log(response);
            console.log('NveService - Multiple documents added.', response);
            return response;
        }).catch((err) => {
            console.log(err);
        });
    }
    multiUpdateNve(m) {
        return this.database.bulkDocs(m).then((response) => {
            console.log(response);
            console.log('NveService - Multiple documents added.', response);
            return response;
        }).catch((err) => {
            console.log(err);
        });
    }
    // pickedUp have process status 3 and loaded status 4
    findPickedUporLoadedUnitsForShipment(processStatus) {
        return this.database.find({
            selector: {
                $and: [
                    // { deliveryAddressNumber: { $eq: deliveryAddressNumber } },
                    { processStatus: { $eq: processStatus } },
                ]
            }
        });
    }
}
NvesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NvesService_Factory() { return new NvesService(); }, token: NvesService, providedIn: "root" });
