<div class="dialog-wrapper">
  <button (click)="close()" class="close-btn" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <div class="text-center">
    <h2>{{ "add-shipping-dialog.title" | translate }}</h2>
  </div>

  <mat-dialog-content class="content-input">
    <mat-form-field style="width: 90%;">
      <mat-label
        >{{ "add-shipping-dialog.delivery-address" | translate }}
      </mat-label>
      <mat-select [(ngModel)]="addShipment.deliveryAddres" name="adrres">
        <mat-option *ngFor="let addres of address" [value]="addres.id">
          {{ addres.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      [disabled]="addShipment.deliveryAddres === ''"
      (click)="addShiping()"
      class="mat-raised-button mat-primary"
    >
      {{ "add-shipping-dialog.add" | translate }}
    </button>
  </mat-dialog-actions>
</div>
