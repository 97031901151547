// zpl small labels are zplBarCodeLabel,zplArticleLabel and zplTestSmallLabel in different resolutions
// 600dpi => PW2124
// 300dpi =>PW1062
// 203dpi => PW710

// zpl barcode label in different dpis
export const zplBarcodeLabel600dpi = `
^XA
^RS,,,3,N,,,1
^RR3
^XZ
^XA
^SZ2^JMA
^MCY^PMN
^PW2124
~JSN
^JZY
^LH0,0^LRN
^XZ
^XA
^FO213,157
^BY20,2.5^B2N,530,N,N,Y^FD NVELABELNUMBER^FS
^FT796,785
^CI0
^A0N,101,136^FD NVELABELCS^FS
^PQ1,0,1,Y
^XZ
`;
export const zplBarcodeLabel300dpi = `
^XA
^RS,,,3,N,,,2
^RR3
^XZ
^XA
^SZ2^JMA
^MCY^PMN
^PW1062
~JSN
^JZY
^LH0,0^LRN
^XZ
^XA
^FO31,50
^BY10,2.5^B2N,265,N,N,Y^FD NVELABELNUMBER^FS
^FT325,364
^CI0
^A0N,51,68^FD NVELABELCS^FS
^PQ1,0,1,Y
^XZ
`;
export const zplBarcodeLabel203dpi = `
^XA
^RS,,,3,N,,,2
^RR3
^XZ
^XA
^SZ2^JMA
^MCY^PMN
^PW710
~JSN
^JZY
^LH0,0^LRN
^XZ
^XA
^FO21,33
^BY7,2.6^B2N,177,N,N,Y^FD NVELABELNUMBER^FS
^FT237,242
^CI0
^A0N,34,46^FD NVELABELCS^FS
^PQ1,0,1,Y
^XZ
`;
// zpl article labels in different resolutions PW2124,PW1062,PW710
export const zplArticleLabel600dpi = `
^XA
^RS,,,3,N,,,1
^RR3
^XZ
^XA
^SZ2^JMA
^MCY^PMN
^PW2124
~JSN
^JZY
^LH0,0^LRN
^XZ
^XA
^FT44,159
^CI0
^A0N,84,113^FDArt.:^FS
^FT266,159
^A0N,84,113^FD ARTICLEBDNO^FS
^FT1097,159
^A0N,84,113^FDBestell Nr.:^FS
^FT1616,159
^A0N,84,113^FD PURCHASEORDERNO^FS
^FT44,247
^A0N,68,92^FD DESIGNATION^FS
^FT1740,262
^A0N,84,113^FD QTY UNIT^FS
^FT44,702
^A0N,76,103^FDReferenz Nr.:^FS
^FT612,702
^A0N,76,103^FD REFEREZNO^FS
^FT44,812
^A0N,76,103^FDAB Nummer:^FS
^FT571,812
^A0N,76,103^FD SUPPLIERORDER^FS
^FT1139,708
^A0N,76,103^FDBD Auftrag:^FS
^FT1616,711
^A0N,76,103^FD BDORDERNO7^FS
^FO371,287
^BY16,2.5^B2N,347,N,N,Y^FD ARTICLENO^FS
^PQ1,0,1,Y
^XZ
`;
export const zplArticleLabel300dpi = `
^XA
^RS,,,3,N,,,2
^RR3
^XZ
^XA
^SZ2^JMA
^MCY^PMN
^PW1062
~JSN
^JZY
^LH0,0^LRN
^XZ
^XA
^FT22,80
^CI0
^A0N,42,57^FDArt.:^FS
^FT131,80
^A0N,42,57^FD ARTICLEBDNO^FS
^FT547,80
^A0N,42,57^FDBestell Nr.:^FS
^FT805,80
^A0N,42,57^FD PURCHASEORDERNO^FS
^FT22,123
^A0N,34,43^FD DESIGNATION^FS
^FT869,131
^A0N,42,57^FD QTY UNIT^FS
^FT22,351
^A0N,38,51^FDReferenz Nr.:^FS
^FT306,351
^A0N,38,51^FD REFEREZNO^FS
^FT22,406
^A0N,38,51^FDAB Nummer:^FS
^FT286,406
^A0N,38,51^FD SUPPLIERORDER^FS
^FT570,354
^A0N,38,51^FDBD Auftrag:^FS
^FT809,356
^A0N,38,51^FD BDORDERNO^FS
^FO186,143
^BY8,2.5^B2N,174,N,N,Y^FD ARTICLENO^FS
^PQ1,0,1,Y
^XZ

`;
export const zplArticleLabel203dpi = `
^XA
^RS,,,3,N,,,2
^RR3
^XZ
^XA
^SZ2^JMA
^MCY^PMN
^PW710
~JSN
^JZY
^LH0,0^LRN
^XZ
^XA
^FT15,53
^CI0
^A0N,28,39^FDArt.:^FS
^FT86,53
^A0N,28,39^FD ARTICLEBDNO^FS
^FT365,53
^A0N,28,39^FDBestell Nr.:^FS
^FT535,53
^A0N,28,39^FD PURCHASEORDERNO^FS
^FT15,83
^A0N,23,29^FD DESIGNATION^FS
^FT580,87
^A0N,28,39^FD QTY UNIT^FS
^FT15,234
^A0N,25,34^FDReferenz Nr.:^FS
^FT202,234
^A0N,25,34^FD REFEREZNO^FS
^FT15,271
^A0N,25,34^FDAB Nummer:^FS
^FT191,271
^A0N,25,34^FD SUPPLIERORDER^FS
^FT379,236
^A0N,25,34^FDBD Auftrag:^FS
^FT540,237
^A0N,25,34^FD BDORDERNO^FS
^FO124,96
^BY5,2.6^B2N,116,N,N,Y^FD ARTICLENO^FS
^PQ1,0,1,Y
^XZ

`;
//zplTestSmallLabel in different resolutions
export const zplTestSmallLabel600dpi = `
^XA
^RS,,,3,N,,,1
^RR3
^XZ
^XA
^SZ2^JMA
^MCY^PMN
^PW2124
~JSN
^JZY
^LH0,0^LRN
^XZ
^XA
^FT44,159
^CI0
^A0N,84,113^FDArt.:^FS
^FT266,159
^A0N,84,113^FD99-10-1045^FS
^FT1097,159
^A0N,84,113^FDBestell Nr.:^FS
^FT1616,159
^A0N,84,113^FD3456789^FS
^FT44,247
^A0N,68,92^FDSkt-Mutter M 6 verz DIN934-8^FS
^FT1740,262
^A0N,84,113^FD50 ST^FS
^FT44,702
^A0N,76,103^FDReferenz Nr.:^FS
^FT612,702
^A0N,76,103^FD471123456^FS
^FT44,812
^A0N,76,103^FDAB Nummer:^FS
^FT571,812
^A0N,76,103^FD23412344^FS
^FT1139,708
^A0N,76,103^FDBD Auftrag:^FS
^FT1616,711
^A0N,76,103^FD1434567^FS
^FO371,287
^BY16,2.5^B2N,347,N,N,Y^FD983175985^FS
^PQ1,0,1,Y
^XZ`;
export const zplTestSmallLabel300dpi = `
^XA
^RS,,,3,N,,,2
^RR3
^XZ
^XA
^SZ2^JMA
^MCY^PMN
^PW1062
~JSN
^JZY
^LH0,0^LRN
^XZ
^XA
^FT22,80
^CI0
^A0N,42,57^FDArt.:^FS
^FT131,80
^A0N,42,57^FD99-10-1045^FS
^FT547,80
^A0N,42,57^FDBestell Nr.:^FS
^FT805,80
^A0N,42,57^FD3456789^FS
^FT22,123
^A0N,34,43^FDSkt-Mutter M 6 verz DIN934-8^FS
^FT869,131
^A0N,42,57^FD50 ST^FS
^FT22,351
^A0N,38,51^FDReferenz Nr.:^FS
^FT306,351
^A0N,38,51^FD471123456^FS
^FT22,406
^A0N,38,51^FDAB Nummer:^FS
^FT286,406
^A0N,38,51^FD23412344^FS
^FT570,354
^A0N,38,51^FDBD Auftrag:^FS
^FT809,356
^A0N,38,51^FD1434567^FS
^FO186,143
^BY8,2.5^B2N,174,N,N,Y^FD983175985^FS
^PQ1,0,1,Y
^XZ
`;
export const zplTestSmallLabel203dpi = `
^XA
^RS,,,3,N,,,2
^RR3
^XZ
^XA
^SZ2^JMA
^MCY^PMN
^PW710
~JSN
^JZY
^LH0,0^LRN
^XZ
^XA
^FT15,53
^CI0
^A0N,28,39^FDArt.:^FS
^FT86,53
^A0N,28,39^FD99-10-1045^FS
^FT365,53
^A0N,28,39^FDBestell Nr.:^FS
^FT535,53
^A0N,28,39^FD3456789^FS
^FT15,83
^A0N,23,29^FDSkt-Mutter M 6 verz DIN934-8^FS
^FT580,87
^A0N,28,39^FD50 ST^FS
^FT15,234
^A0N,25,34^FDReferenz Nr.:^FS
^FT202,234
^A0N,25,34^FD471123456^FS
^FT15,271
^A0N,25,34^FDAB Nummer:^FS
^FT191,271
^A0N,25,34^FD23412344^FS
^FT379,236
^A0N,25,34^FDBD Auftrag:^FS
^FT540,237
^A0N,25,34^FD1434567^FS
^FO124,96
^BY5,2.6^B2N,116,N,N,Y^FD983175985^FS
^PQ1,0,1,Y
^XZ
`;

