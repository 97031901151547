export const PACKAGING_TYPES = [
    {
        id: 1,
        description: 'Unpacked',
        length: '',
        width: '',
        height: ''
    },
    {
        id: 9,
        description: 'Pallet / 120x80',
        length: 120.00,
        width: 80.00,
        height: ''
    },
    {
        id: 10,
        description: 'Collar pallet / 120x80',
        length: 120.00,
        width: 80.00,
        height: ''
    },
    {
        id: 11,
        description: 'Cask',
        length: '',
        width: '',
        height: ''
    },
    {
        id: 12,
        description: 'Wire cage pallet / 124x84x97',
        length: 124.00,
        width: 84.00,
        height: 97.00
    },
    {
        id: 13,
        description: 'Iron rack / 80x65',
        length: '',
        width: 80.00,
        height: 65.00
    },
    {
        id: 19,
        description: 'Iron rack / 100x55',
        length: '',
        width: 100.00,
        height: 55.00
    },
    {
        id: 21,
        description: '1 Collar pallet / 120x80x35',
        length: 120.00,
        width: 80.00,
        height: 35.00
    },
    {
        id: 22,
        description: '2 Collar pallet / 120x80x56',
        length: 120.00,
        width: 80.00,
        height: 56.00
    },
    {
        id: 23,
        description: '3 Collar pallet / 120x80x73',
        length: 120.00,
        width: 80.00,
        height: 73.00
    },
    {
        id: 24,
        description: '4 Collar pallet / 120x80x92',
        length: 120.00,
        width: 80.00,
        height: 92.00
    },
    {
        id: 25,
        description: '5 Collar pallet / 120x80x119',
        length: 120.00,
        width: 80.00,
        height: 119.00
    },
    {
        id: 26,
        description: '6 Collar pallet / 120x80x131',
        length: 120.00,
        width: 80.00,
        height: 131.00
    },
    {
        id: 27,
        description: '7 Collar pallet / 120x80x151',
        length: 120.00,
        width: 80.00,
        height: 151.00
    },
    {
        id: 62,
        description: 'Vendor / carton',
        length: '',
        width: '',
        height: ''
    },
    {
        id: 63,
        description: 'Vendor / pallet',
        length: '',
        width: '',
        height: ''
    },
    {
        id: 64,
        description: 'Vendor / bundle',
        length: '',
        width: '',
        height: ''
    },
    {
        id: 65,
        description: 'Vendor / roll',
        length: '',
        width: '',
        height: ''
    },
    {
        id: 66,
        description: 'Vendor / case',
        length: '',
        width: '',
        height: ''
    },
    {
        id: 68,
        description: 'Vendor / pallet-box / 120x80',
        length: 120.00,
        width: 80.00,
        height: ''
    },
    {
        id: 98,
        description: 'Wooden / 120x80x23',
        length: 120.00,
        width: 80.00,
        height: 23.00
    },
    {
        id: 614,
        description: 'Vendor bag',
        length: '',
        width: '',
        height: ''
    },
    {
        id: 631,
        description: 'Vendor / 80x60',
        length: 80.00,
        width: 60.00,
        height: ''
    },
    {
        id: 632,
        description: 'Vendor / 100x100',
        length: 100.00,
        width: 100.00,
        height: ''
    },
    {
        id: 633,
        description: 'Vendor / 120x80',
        length: 120.00,
        width: 80.00,
        height: ''
    },
    {
        id: 634,
        description: 'Vendor / 120x110',
        length: 120.00,
        width: 110.00,
        height: ''
    },
    {
        id: 635,
        description: 'Vendor / 120x120',
        length: 120.00,
        width: 120.00,
        height: ''
    },
    {
        id: 636,
        description: 'Vendor / 245x35',
        length: 245.00,
        width: 35.00,
        height: ''
    },
    {
        id: 637,
        description: 'Vendor / 300x70',
        length: 303.00,
        width: 70.00,
        height: ''
    },
    {
        id: 638,
        description: 'Vendor / 310x35',
        length: 310.00,
        width: 35.00,
        height: ''
    },
    {
        id: 639,
        description: 'Vendor / 455x100',
        length: 455.00,
        width: 100.00,
        height: ''
    },
    {
        id: 640,
        description: 'Vendor / 510x55',
        length: 510.00,
        width: 55.00,
        height: ''
    },
    {
        id: 641,
        description: 'Vendor / 520x55',
        length: 520.00,
        width: 55.00,
        height: ''
    },
    {
        id: 642,
        description: 'Palet / 140x140',
        length: 140.00,
        width: 140.00,
        height: ''
    },
    {
        id: 6165,
        description: 'Case / 165x115x70',
        length: 165.00,
        width: 115.00,
        height: 70.00
    },
    {
        id: 6200,
        description: 'Case / 200x130x43',
        length: 200.00,
        width: 130.00,
        height: 43.00
    },
    {
        id: 6215,
        description: 'Case / 215x110x100',
        length: 215.00,
        width: 110.00,
        height: 100.00
    },
    {
        id: 6220,
        description: 'Case / 220x80x70',
        length: 220.00,
        width: 80.00,
        height: 70.00
    },
    {
        id: 6260,
        description: 'Case / 260x100x62',
        length: 260.00,
        width: 100.00,
        height: 62.00
    },
    {
        id: 6265,
        description: 'Case / 265x115x43',
        length: 265.00,
        width: 115.00,
        height: 43.00
    },
    {
        id: 6320,
        description: 'Case / 320x110x70',
        length: 320.00,
        width: 110.00,
        height: 70.00
    }
];
