import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-complete-unit-actions',
  templateUrl: './complete-unit-actions.component.html',
  styleUrls: ['./complete-unit-actions.component.scss']
})
export class CompleteUnitActionsComponent implements OnInit, OnDestroy {

  isOnline = false;
  hideCopyButton = false;
  showAccessoryButton = false;

  constructor(
    private dialogRef: MatDialogRef<CompleteUnitActionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  changeValueFromInput(val) {
    console.log(val);

  }

  ngOnInit() {
    this.isOnline = navigator.onLine;
    this.hideCopyButton = this.data.hideCopyButton;
    this.showAccessoryButton = this.data.showAccessoryButton;
    console.log('COmpletter isOnline?', this.isOnline);

  }

  action(action: string) {
    this.dialogRef.close(action);

  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    console.log('component destroy');

  }

}
