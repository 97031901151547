// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Material modules
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatCheckbox, MatCheckboxModule} from '@angular/material';
import {MatBadgeModule} from '@angular/material/badge';

// Dialog Components
import { NveBarcodeDialogComponent } from './nve-barcode-dialog/nve-barcode-dialog.component';
import { ArticleLabelDialogComponent } from './article-label-dialog/article-label-dialog.component';
import { PackingDialogComponent } from './packing-dialog/packing-dialog.component';
import { AssigneArticleDialogComponent } from './assigne-article-dialog/assigne-article-dialog.component';
import { CompleteUnitDialogComponent } from './complete-unit-dialog/complete-unit-dialog.component';
import { CopyUnitDialogComponent } from './copy-unit-dialog/copy-unit-dialog.component';
import { EquallyRankedDialogComponent } from './equally-ranked-dialog/equally-ranked-dialog.component';
import { AccessoryUnitDialogComponent } from './accessory-unit-dialog/accessory-unit-dialog.component';
import { CompleteUnitActionsComponent } from './complete-unit-actions/complete-unit-actions.component';
import { FinalCheckDialogComponent } from './final-check-dialog/final-check-dialog.component';
import { AddShippingDialogComponent } from './add-shipping-dialog/add-shipping-dialog.component';
import { ShipmentDialogComponent } from './shipment-dialog/shipment-dialog.component';
import { PrintShippingDialogComponent } from './print-shipping-dialog/print-shipping-dialog.component';
import {MessagesForLaserScanDialogComponent} from './shipment-dialog/messages-for-laser-scan-dialog.component';
import {FilterDialogComponent} from './filter-dialog/filter-dialog.component';
import {PackMoreThanOrderDialogComponent} from './pack-more-than-order-dialog/pack-more-than-order-dialog.component';
import {PrintStatusMessageDialogComponent} from "./print-status-message-dialog/print-status-message-dialog.component";
// External modules
import { BarecodeScannerLivestreamModule } from 'ngx-barcode-scanner';
import { TranslateModule } from '@ngx-translate/core';

// Shared
import { NaviBarComponent } from './navi-bar/navi-bar.component';
import { AddRemoveInputComponent } from './add-remove-input/add-remove-input.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { OrderPurchaseTitlComponent } from './order-purchase-title/order-purchase-title.component';
import { UpdatePendingDialogComponent } from './update-pending-dialog/update-pending-dialog.component';
import {FocusOnShowDirectiveComponent} from './add-remove-input/focus-on-show-directive.component';
import {ChangeFocusOnInputByEnterDirective} from './directives/change-focus-on-input-by-enter.directive';
import {AutoFocusOnInputDirective} from './directives/auto-focus-on-input.directive';
import {LogoutButtonComponent} from './logout-button/logout-button.component';

const COMPONENTS = [
  NaviBarComponent,
  NveBarcodeDialogComponent,
  ArticleLabelDialogComponent,
  AddRemoveInputComponent,
  PackingDialogComponent,
  AssigneArticleDialogComponent,
  CompleteUnitDialogComponent,
  CompleteUnitActionsComponent,
  CopyUnitDialogComponent,
  EquallyRankedDialogComponent,
  AccessoryUnitDialogComponent,
  FinalCheckDialogComponent,
  AddShippingDialogComponent,
  ShipmentDialogComponent,
  PrintShippingDialogComponent,
  SnackBarComponent,
  DeleteDialogComponent,
  OrderPurchaseTitlComponent,
  UpdatePendingDialogComponent,
  FocusOnShowDirectiveComponent,
  MessagesForLaserScanDialogComponent,
  LogoutButtonComponent,
  FilterDialogComponent,
  PackMoreThanOrderDialogComponent,
  PrintStatusMessageDialogComponent
];

const PIPES = [];

const MODULES = [
  RouterModule,
  CommonModule,
  HttpClientModule,
  ReactiveFormsModule,
  FormsModule,
  MatSliderModule,
  MatGridListModule,
  MatInputModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRippleModule,
  MatCardModule,
  MatButtonModule,
  MatToolbarModule,
  MatSidenavModule,
  MatListModule,
  MatIconModule,
  BarecodeScannerLivestreamModule,
  MatPaginatorModule,
  MatDialogModule,
  MatTabsModule,
  MatButtonToggleModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  TranslateModule,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatBadgeModule
];
const DIRECTIVES = [
  ChangeFocusOnInputByEnterDirective,
  AutoFocusOnInputDirective
];


@NgModule({
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES
  ],
  exports: [
    ...MODULES,
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES
  ],
  imports: [
    ...MODULES,
  ],
  entryComponents: [
    NveBarcodeDialogComponent,
    ArticleLabelDialogComponent,
    PackingDialogComponent,
    AssigneArticleDialogComponent,
    CopyUnitDialogComponent,
    CompleteUnitDialogComponent,
    CompleteUnitActionsComponent,
    EquallyRankedDialogComponent,
    AccessoryUnitDialogComponent,
    FinalCheckDialogComponent,
    AddShippingDialogComponent,
    ShipmentDialogComponent,
    PrintShippingDialogComponent,
    SnackBarComponent,
    DeleteDialogComponent,
    UpdatePendingDialogComponent,
    MessagesForLaserScanDialogComponent,
    FilterDialogComponent,
    PackMoreThanOrderDialogComponent,
    PrintStatusMessageDialogComponent
  ],
})
export class SharedModule { }
