import {Directive, ElementRef, HostListener, OnInit} from '@angular/core';

@Directive({
  selector: '[appChangeFocusOnNextEmptyField]'
})
export class ChangeFocusOnInputByEnterDirective implements OnInit {
  constructor(private elementRef: ElementRef) {
  }

  @HostListener('keyup', ['$event']) onKeyDown(eventData: KeyboardEvent) {
    if (eventData.key === 'Enter') {
      eventData.preventDefault();
      const target = eventData.target as | HTMLInputElement | HTMLTextAreaElement;
      let siblingsChild = this.findSiblingsChild(target);
      while (siblingsChild.value !== '') {
        siblingsChild = this.findSiblingsChild(siblingsChild);
        siblingsChild.focus();
      }
      if (siblingsChild.value === '') {
        siblingsChild.focus();
        return;
      }

    }
  }

  findSiblingsChild(target) {
    const formfield = target.parentElement.parentElement.parentElement.parentElement;
    const sibling = formfield.nextElementSibling;
    const siblingsChild = sibling.firstElementChild.firstElementChild.firstElementChild.firstElementChild as HTMLInputElement;
    return siblingsChild;
  }

  ngOnInit() {
  }
}
