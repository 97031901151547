import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-add-shipping-dialog',
  templateUrl: './add-shipping-dialog.component.html',
  styleUrls: ['./add-shipping-dialog.component.scss']
})
export class AddShippingDialogComponent implements OnInit {

  addShipment = {
    designation: '',
    deliveryAddres: ''
  };
  address: any[] = [
    {id: '843', text: 'Big Dutchman International GmbH'},
    {id: '613862', text: 'Hellmann Poultry GmbH & Co. KG'},
    {id: '507902', text: 'Hellmann Lager Schlosserei'},
    {id: '1842652', text: 'BD PowerSystems GmbH & Co. KG'},
    {id: '10', text: this.translate.instant('add-shipping-dialog.direct-delivery')}
  ];

  constructor(
    private dialogRef: MatDialogRef<AddShippingDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
  }

  inputChange(val) {
    this.addShipment.designation = val;
  }

  addShiping() {
    this.dialogRef.close(this.addShipment);
  }

  close() {
    this.dialogRef.close();
  }

}




