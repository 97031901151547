<div class="dialog-wrapper">
  <button (click)="close()" class="close-btn" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <div class="text-center">
    <p><strong>{{article.bdArticleName}}</strong></p>
    <p><strong>{{article.bdArticleNumber}}</strong></p>
    <p><strong>{{article.supplierArticleNumber}}</strong></p>
    <p>
      {{'equally-ranked-dialog.article-qty' | translate }}
      <strong>{{article.articleQtyOrdered - article.articleQtyPacked}}</strong>
    </p>

    <mat-dialog-content class="content-input">
      <app-add-remove-input
        [placeholder]="'equally-ranked-dialog.unit-qty'"
        [value]="unitQuantity"
        (changeValue)="changeValueFromInput($event, 'unitQuantity')"
      ></app-add-remove-input>
      <app-add-remove-input
        [placeholder]="'equally-ranked-dialog.article-qty-per-unit'"
        [value]="articleQuantityPerUnit"
        (changeValue)="changeValueFromInput($event, 'articleQuantityPerUnit')"
      ></app-add-remove-input>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        [disabled]="unitQuantity * articleQuantityPerUnit > maxQty"
        class="mat-raised-button mat-primary"
        (click)="copyUnit()"
      >
      {{'equally-ranked-dialog.proceed' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
