<div class="content-input">
  <mat-grid-list cols="6" rowHeight="80px">
    <mat-grid-tile [colspan]="1">
      <button
        (keydown.enter)="$event.preventDefault()"
        (click)="addRemoveValue(true)"
        mat-stroked-button
        class="text-center"
      >
        <mat-icon>add</mat-icon>
      </button>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="4">
      <mat-form-field class="my-form-field" style="width: 90%;">
        <input
          min="1"
          matInput
          type="number"
          #inputField
          (keyup)="inputChange(inputField.value)"
          (blur)="setValuOnBlur(inputField.value)"
          [placeholder]="translatedPlaceholder"
          appPrefixFocusAndSelect
        />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <button
        (keydown.enter)="$event.preventDefault()"
        (click)="addRemoveValue(false)"
        class="text-center"
        mat-stroked-button
      >
        <mat-icon>remove</mat-icon>
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</div>
