import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import {MatDialogRef, MAT_DIALOG_DATA, MAT_DATE_FORMATS, MatCheckboxModule} from '@angular/material';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';


import { ShippingListTemplateComponent } from './shipping-list-template/shipping-list-template.component';

import { PrintComponent } from './print/print.component';

import { registerLocaleData } from '@angular/common';

import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { PrinterTestComponent } from './printer-test/printer-test.component';
import { PrinterConfigurationComponent } from './modules/printer-configuration/printer-configuration.component';
import { PrinterDefaultComponent } from './modules/printer-configuration/printer-default/printer-default.component';
import { PrintMenagerComponent } from './modules/printer-configuration/print-menager/print-menager.component';
import { PrinterLabelComponent } from './modules/printer-configuration/printer-label/printer-label.component';
import { PrinterSetupComplitedComponent } from './modules/printer-configuration/printer-setup-complited/printer-setup-complited.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';


registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de', localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    ShippingListTemplateComponent,
    PrintComponent,
    PrinterTestComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MatCheckboxModule
  ],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: 'en-GB' }, {
    provide: MatDialogRef,
    useValue: {}
  }, {
    provide: MAT_DIALOG_DATA,
    useValue: {} // Add any data you wish to test if it is passed/used correctly
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
