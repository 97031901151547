import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-add-remove-input',
  templateUrl: './add-remove-input.component.html',
  styleUrls: ['./add-remove-input.component.scss']
})
export class AddRemoveInputComponent implements OnInit {
  @Input() value;
  @Input() placeholder;
  @Input() maxNumber?;
  @Input() isDecimal?;

  @Output() changeValue = new EventEmitter();

  translatedPlaceholder: string;

  @ViewChild('inputField', { static: true }) inputField: ElementRef;
  ngOnInit() {

    this.translate.get(this.placeholder).subscribe((res: string) => {
      this.translatedPlaceholder = res;
    });
    this.inputField.nativeElement.value = this.value;
    if (Number(this.maxNumber) < 0) {
      this.inputField.nativeElement.value = 0;
    }
  }

  constructor(private translate: TranslateService ) {

  }
  addRemoveValue(bool) {
    if (!this.inputField.nativeElement.value) {
      this.inputField.nativeElement.value = 0;
    }
    bool ? this.inputField.nativeElement.value++ : this.inputField.nativeElement.value--;
    if (this.inputField.nativeElement.value < 0) {
      this.inputField.nativeElement.value = 0;
    }
    if (this.maxNumber || Number(this.maxNumber) === 0) {
      if (!this.placeholder.startsWith('assigne-article-dialog')) {
        if (Number(this.inputField.nativeElement.value) > Number(this.maxNumber)) {
          this.inputField.nativeElement.value = this.maxNumber;
        }
      }
    }
    this.changeValue.emit(this.inputField.nativeElement.value);
  }

  inputChange(val) {


    if (!String(val).match(new RegExp(/^\d+$/))) {

      if (!this.isDecimal) {
        this.inputField.nativeElement.value = val.replace(/[^0-9.-]/g, '').replace(/(\..*)\./g, '$1').replace(/(?!^)-/g, '');
      } else {
        this.inputField.nativeElement.value = val.replace(/[^0-9]/g, '');
      }
    }
    if (val === '') {
      this.inputField.nativeElement.value = null;

    }
    if (this.maxNumber) {
      if (!this.placeholder.startsWith('assigne-article-dialog')) {
        if (Number(this.inputField.nativeElement.value) > Number(this.maxNumber)) {
          this.inputField.nativeElement.value = this.maxNumber;
        }
      }
    }
    this.changeValue.emit(+this.inputField.nativeElement.value);
  }

  setValuOnBlur(val) {

    if (!this.isDecimal) {
      // this.inputField.nativeElement.value = Number(val).toFixed(2);
      console.log(val, 'val is:', typeof val);
      this.inputField.nativeElement.value = Number(val);
    } else {
      this.inputField.nativeElement.value = Number(val);
    }

    this.changeValue.emit(+this.inputField.nativeElement.value);
  }


}
