/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./update-pending-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./update-pending-dialog.component";
var styles_UpdatePendingDialogComponent = [i0.styles];
var RenderType_UpdatePendingDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UpdatePendingDialogComponent, data: {} });
export { RenderType_UpdatePendingDialogComponent as RenderType_UpdatePendingDialogComponent };
export function View_UpdatePendingDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 4, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "mat-raised-button mat-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmUpdate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("purchase-order-overview.update-dialog")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("purchase-order-overview.proceed")); _ck(_v, 9, 0, currVal_1); }); }
export function View_UpdatePendingDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-update-pending-dialog", [], null, null, null, View_UpdatePendingDialogComponent_0, RenderType_UpdatePendingDialogComponent)), i1.ɵdid(1, 114688, null, 0, i4.UpdatePendingDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UpdatePendingDialogComponentNgFactory = i1.ɵccf("app-update-pending-dialog", i4.UpdatePendingDialogComponent, View_UpdatePendingDialogComponent_Host_0, {}, {}, []);
export { UpdatePendingDialogComponentNgFactory as UpdatePendingDialogComponentNgFactory };
