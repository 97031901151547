import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Component({
  selector: 'app-copy-unit-dialog',
  templateUrl: './copy-unit-dialog.component.html',
  styleUrls: ['./copy-unit-dialog.component.scss']
})
export class CopyUnitDialogComponent implements OnInit, OnDestroy {

  unitQuantity = 1;
  nextNVE: number;
  maxQty: number;
  itemQty: number;
  maxUnitsAllowed: number;
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private suppliersService: SuppliersService,
    private dialogRef: MatDialogRef<CopyUnitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log('copy unit dialog data=', this.data);
    this.maxQty = this.data.maxQty ? this.data.maxQty : 0;
    this.itemQty = this.data.itemQty ? this.data.itemQty : 1;

    console.log(' .. maxQty=', this.maxQty);
    console.log(' .. itemQty=', this.itemQty);


    this.maxUnitsAllowed = Math.round(this.maxQty / this.itemQty);

    console.log(' .. maxUnitsAllowed=', this.maxUnitsAllowed);

    this.suppliersService.getCurrent(this.data.supplierId);
    this.suppliersService.curent$.subscribe(cur => {
      this.nextNVE = Number(cur) + 1;

    });

  }

  changeValueFromInput(value) {
    console.log(' ... input value=', value);
    if (+value > this.maxUnitsAllowed) {
      console.log(' ... round down. Qty * units too big. ', this.maxUnitsAllowed);

      this.unitQuantity = this.maxUnitsAllowed;
      console.log(' ... unitQuantity=', this.unitQuantity);
    } else {
      this.unitQuantity = +value;
      console.log(' ... unitQuantity=', this.unitQuantity);
    }

  }

  copyUnit() {
    console.log(' ... copy units for supplier ', this.unitQuantity);
    this.suppliersService.getLabels(this.data.supplierId, this.unitQuantity)
      .pipe(takeUntil(this.destroy$))
      .subscribe(labels => {
        this.dialogRef.close(labels);
      });
  }

  cancelCopy() {
    this.dialogRef.close(false);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
