<div class="dialog-wrapper">
  <button (click)="closeDialog()" class="close-btn" mat-large mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <div class="text-center">
    <h2>{{'nve-barcode-dialog.title' | translate}}</h2>
  </div>

  <mat-dialog-content class="content-input">
    <app-add-remove-input
      [placeholder]="'nve-barcode-dialog.label-qty'"
      [value]="qty"
      (changeValue)="changeValueFromInput($event)"
    ></app-add-remove-input>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      [ngClass]="{ disabled: qty === 0 || qty === null }"
      [disabled]="qty === 0 || qty === null"
      class="mat-raised-button mat-primary"
      (click)="printNVEBarcode()"
    >
    {{'nve-barcode-dialog.print' | translate}}
    </button>
  </mat-dialog-actions>
</div>
