import { Component, OnInit, Inject, ViewChild, OnDestroy, Renderer2 } from '@angular/core';
import { BarecodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NvesService } from 'src/app/services/nves.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shipment-dialog',
  templateUrl: './shipment-dialog.component.html',
  styleUrls: ['./shipment-dialog.component.scss']
})
export class ShipmentDialogComponent implements OnInit, OnDestroy {

  scannerMode = true;
  scanner: any;
  nveRange: any;
  inputValue = '';
  purchaseOrder: any;
  isArticle: boolean;
  isEqually: boolean;
  showStatus = 'scanner-start';
  barcodeNumber: number;
  msg: string;
  nves: string[];
  mode: string;
  @ViewChild(BarecodeScannerLivestreamComponent, null)
  barecodeScanner: BarecodeScannerLivestreamComponent;

  constructor(
    private nveDb: NvesService,
    private dialogRef: MatDialogRef<ShipmentDialogComponent>,
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }


  ngOnInit() {

    this.scanner = JSON.parse(localStorage.getItem('scanner'));
    this.scannerMode = this.scanner.isScanner;
    this.nveRange = JSON.parse(localStorage.getItem('nveRangeConfig'));
    this.nves = this.data.nves;
    this.mode = this.data.mode;
    console.log('ngOnInit  -   ShipmentDialogComponent');
    console.log('this.scannerMode in ngOninit', this.scannerMode);

   /* if (this.scannerMode) {
      setTimeout(() => {
        this.showStatus = 'scanner-progress';
        setTimeout(() => {
          if (this.barecodeScanner) {
            this.barecodeScanner.start();
          }
        });
      }, 2000);
    } else {

      setTimeout(() => {
       // this.renderer.selectRootElement('#myInput').focus();
      },2000);
    }*/
    this.startScanner();


  }


  closeDialog() {
    this.showStatus = 'scanner-finish';
    setTimeout(() => {
      this.dialogRef.close();

    });
  }

  // camera scan
  onValueChanges(result) {
    this.msg = '';
    if (this.barcodeNumber !== result.codeResult.code) {
      if (this.checkSumBarcode(result.codeResult.code)) {

        this.msg ='checking';
        this.showStatus = 'scanner-finish';

        if (result.codeResult.code.length === 10) {
          const tempBarcodeNumber = result.codeResult.code.slice(0, -1);
          console.log('nve scan detected', tempBarcodeNumber);
          if (this.nveRange && (+tempBarcodeNumber < this.nveRange.startNVE || +tempBarcodeNumber > this.nveRange.endNVE)) {
            this.msg = this.translate.instant('shipping-dialog.nve-not-in-range', { nveNumber: tempBarcodeNumber });
            console.log('nve not in range', tempBarcodeNumber);

          } else {

            const foundNveNumber = this.nves.find(item => item === tempBarcodeNumber);
            if (foundNveNumber) {
              this.barcodeNumber = tempBarcodeNumber;
              this.msg = '';
            } else {
              if (this.mode == 'assigned') {
                this.msg = this.translate.instant('shipping-dialog.nve-in-shippinglist', { nveNumber: tempBarcodeNumber });
              } else {
                this.msg = this.translate.instant('shipping-dialog.nve-in-list', { nveNumber: tempBarcodeNumber });
              }
            }


          }
        }
      }
    }
  }

  scanAgain() {
    setTimeout(() => {
      this.showStatus = 'scanner-progress';
      this.barcodeNumber = null;
      setTimeout(() => {
        this.barecodeScanner.start();
      });
    },1000);
  }

  confirm() {
    this.showStatus = 'scanner-finish';
  }

  submitBarcode() {
    this.showStatus = 'scanner-finish';
    setTimeout(() => {
      this.dialogRef.close({ scanNumber: this.barcodeNumber, isEqually: this.isEqually });
    });

  }


  checkSumBarcode(code) {
    const numArr = Array.from(String(code), Number);
    const check = numArr[numArr.length - 1];
    numArr.splice(-1, 1);
    const sum = numArr.reduce((acc, c, i) => {
      i % 2 !== 0 ? acc += c : acc += (c * 3);
      return acc;
    }, 0);
    let mod = 10 - (sum % 10);
    if (mod === 10) {
      mod = 0;
    }
    return mod === check;
  }

  // laser scan
 /* submit() {
    this.msg = '';
    this.showStatus = 'scanner-finish';
    if (this.inputValue.toString().length === 9 && this.inputValue.toString().charAt(0) !== '0') {
      this.msg = 'checking';
      const tempBarcodeNumber = this.inputValue.toString();
      console.log('nve scan detected', tempBarcodeNumber);
      if (this.nveRange && (+tempBarcodeNumber < this.nveRange.startNVE || +tempBarcodeNumber > this.nveRange.endNVE)) {
        this.msg = this.translate.instant('shipping-dialog.nve-not-in-range', { nveNumber: tempBarcodeNumber });
        console.log('nve not in range', tempBarcodeNumber);

      } else {
        const foundNveNumber = this.nves.find(item => item === this.inputValue.toString());
        if (foundNveNumber) {
          this.msg = '';
          setTimeout(() => {
            this.dialogRef.close({ scanNumber: this.inputValue.toString(), isEqually: this.isEqually });
          });
        } else {
          if (this.mode == 'assigned') {
            this.msg = this.translate.instant('shipping-dialog.nve-in-shippinglist', { nveNumber: tempBarcodeNumber });
          } else {
            this.msg = this.translate.instant('shipping-dialog.nve-in-list', { nveNumber: tempBarcodeNumber });
          }
        }


      }
    } else {
      this.msg = this.translate.instant('shipping-dialog.nve-in-list', { nveNumber: this.inputValue.toString() });
    }
  }*/



  startScanner() {

    // this.scannerMode = !this.scannerMode;
    this.scannerMode = true;
    this.scanner.isScanner = this.scannerMode;
    localStorage.setItem('scanner', JSON.stringify(this.scanner));
    console.log('this.scannerMode in startScanner()', this.scannerMode);
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      data: this.scannerMode ? this.translate.instant('snack-bar-message.camera-mode')
        : this.translate.instant('snack-bar-message.scanner-mode')
    });
    if (this.scannerMode) {
      setTimeout(() => {
        this.showStatus = 'scanner-progress';
        this.barcodeNumber = null;
        setTimeout(() => {
          this.barecodeScanner.start();
        });
      });
    } else {
      setTimeout(() => {
        // this.renderer.selectRootElement('#myInput').focus();
      });
    }
  }

  clearMsg() {
    this.msg = '';
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy  -   ShipmentDialogComponent');
    this.scannerMode = false;
    console.log('scannerMode in ngOnDestroy', this.scannerMode);
    this.showStatus = 'scanner-finish';
  }

}
