import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from 'src/environments/environment';
import { SnackBarComponent } from '../shared/snack-bar/snack-bar.component';
import { map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/material/snack-bar";
export class LoginService {
    constructor(router, httpClient, translate, snackBar) {
        this.router = router;
        this.httpClient = httpClient;
        this.translate = translate;
        this.snackBar = snackBar;
        this.handleError = (error) => {
            console.log('Login Error ', error);
            this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'right',
                data: `${error.message}`
            });
            return throwError(error);
        };
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.$currentUser = this.currentUserSubject.asObservable();
        this.currentTokenSubject = new BehaviorSubject(localStorage.getItem('apiToken'));
        this.$currentToken = this.currentUserSubject.asObservable();
        this.printerConfigSubject = new BehaviorSubject([]);
        this.$printerConfig = this.printerConfigSubject.asObservable();
        this.nveRangeConfigSubject = new BehaviorSubject([]);
        this.$nveRangeConfig = this.nveRangeConfigSubject.asObservable();
        this.logoutSuccessSubject = new BehaviorSubject(false);
        this.$logoutSuccess = this.logoutSuccessSubject.asObservable();
    }
    get currentUser() {
        return this.currentUserSubject.value;
    }
    get currentToken() {
        return this.currentTokenSubject.value;
    }
    get logoutSuccess() {
        console.log('logoutSuccess value ', this.logoutSuccessSubject.value);
        return this.logoutSuccessSubject.value;
    }
    getToken() {
        //      this.httpClient.get('/service/auth/token')
        this.httpClient.get('/auth/token')
            .subscribe(r => this.handleTokenSuccess(r), error => this.handleTokenError(error));
    }
    handleTokenSuccess(apiToken) {
        console.log('apiToken', apiToken);
        this.logoutSuccessSubject.next(false);
        this.apiToken = apiToken.token;
        localStorage.setItem('apiToken', apiToken.token);
        this.currentTokenSubject.next(apiToken.token);
        this.callApi();
    }
    getSuppliersPrinter(id) {
        const headers = new HttpHeaders({ Authorization: 'Basic ' + btoa(environment.techuser + ':' + environment.sdaSupplierApiPassword) });
        this.httpClient.get(`${environment.sdaSupplierApiHost}/api/suppliers/supplier/${id}/printers`, { headers })
            .subscribe(printer => {
            console.log('PRINTER', printer);
            if (printer) {
                this.printerConfigSubject.next(printer);
            }
            else {
                printer = [];
            }
            localStorage.setItem('printerConfig', JSON.stringify(printer));
            if (localStorage.getItem('mySmallPrinter') === null) {
                var printers = JSON.parse(localStorage.getItem('printerConfig'));
                const smallPrinter = printers.find(printer => printer.type === 'label' && printer.isDefault);
                if (smallPrinter) {
                    console.log('setting mySmallPrinter', smallPrinter);
                    localStorage.setItem('mySmallPrinter', JSON.stringify(smallPrinter));
                }
            }
            if (localStorage.getItem('myBigPrinter') === null) {
                var printers = JSON.parse(localStorage.getItem('printerConfig'));
                const bigPrinter = printers.find(printer => printer.type === 'biglabel' && printer.isDefault);
                if (bigPrinter) {
                    console.log('setting myBigPrinter', bigPrinter);
                    localStorage.setItem('myBigPrinter', JSON.stringify(bigPrinter));
                }
            }
        }, error => this.handleTokenError(error));
    }
    getSuppliersNVERange(id) {
        const headers = new HttpHeaders({ Authorization: 'Basic ' + btoa(environment.techuser + ':' + environment.sdaSupplierApiPassword) });
        this.httpClient.get(`${environment.sdaSupplierApiHost}/api/suppliers/supplier/${id}/nverange`, { headers })
            .subscribe(nverange => {
            console.log('nverange', nverange);
            if (nverange) {
                this.nveRangeConfigSubject.next(nverange);
            }
            localStorage.setItem('nveRangeConfig', JSON.stringify(nverange));
        }, error => this.handleTokenError(error));
    }
    handleTokenError(error) {
        console.log('LOGIN URL ', environment.loginUrl);
        if (error.status === 401) {
            localStorage.removeItem('apiToken');
            localStorage.removeItem('currentUser');
            localStorage.removeItem('printerConfig');
            localStorage.removeItem('nveRangeConfig');
            this.currentTokenSubject.next(null);
            this.currentUserSubject.next(null);
            console.log('LOGIN URL ', environment.loginUrl);
            setTimeout(() => window.location.replace(`${environment.loginUrl}saml/login`), 4000);
            // setTimeout(() => window.location.replace(`/service/saml/login`), 4000);
        }
    }
    callApi() {
        const apiToken = localStorage.getItem('apiToken');
        console.log('Token ', apiToken);
        this.httpClient.get(`/api/user/`, {
            headers: {
                'x-auth-token': apiToken
            }
        }).subscribe((user) => {
            console.log('User authorized and set local storage ', user);
            if (user.languageId) {
                console.log('User chooses language ', user.languageId);
                this.translate.use(user.languageId);
            }
            else {
                console.log('No user language. Use default');
                this.translate.use('de');
                user.languageId = 'de';
            }
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.apiResult = JSON.stringify(user);
            this.currentUserSubject.next(this.apiResult);
            this.getSuppliersPrinter(user.supplierId);
            this.getSuppliersNVERange(user.supplierId);
            // if (environment.licenseenabled) {
            //   this.getJSPLicence();
            // }
            this.router.navigate(['overview']);
        }, err => {
            this.handleError(err);
        });
    }
    logout() {
        localStorage.removeItem('apiToken');
        localStorage.removeItem('currentUser');
        //localStorage.removeItem('printerConfig');
        //localStorage.removeItem('nveRangeConfig');
        //localStorage.removeItem('licenseUrl');
        this.currentTokenSubject.next(null);
        this.currentUserSubject.next(null);
        //this.printerConfigSubject.next([]);
        console.log('Login service.  Send SAML logout ...');
        const headers = new HttpHeaders({
            Accept: 'text/html'
        });
        return this.httpClient.get(`/saml/logout`, { headers: headers, responseType: 'text' }).pipe(map((htmlString) => { return htmlString; }));
    }
}
LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.TranslateService), i0.ɵɵinject(i4.MatSnackBar)); }, token: LoginService, providedIn: "root" });
