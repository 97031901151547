<div>
  <mat-grid-list class="feedback-nav-fix" color="primary" cols="5" rowHeight="50px">
    <mat-grid-tile>
      <a
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['overview']"
        mat-button>
        <img src="assets/icons/Document.svg"/>
      </a>
    </mat-grid-tile>
    <mat-grid-tile>
      <a (click)="openNVE()" mat-button>
       <img src="assets/icons/barcode-16px.svg"/>
      </a
    ></mat-grid-tile>
    <mat-grid-tile>
      <a
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['nve-overview']"
        mat-button
      >
        <img src="assets/icons/LKW_orange.svg"/>
      </a>
    </mat-grid-tile>
    <mat-grid-tile>
      <a (click) ='openFeedback()'>
        <img src="assets/icons/Feedback_orange.svg"/>
      </a>
    </mat-grid-tile>
    <mat-grid-tile>
      <a
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLink="/printer-configuration"
      >
        <img src="assets/icons/Settings_orange.svg"/>
      </a>
    </mat-grid-tile>
  </mat-grid-list>
</div>
