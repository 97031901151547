/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/material/dialog";
import * as i4 from "./delete-dialog.component";
var styles_DeleteDialogComponent = [i0.styles];
var RenderType_DeleteDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeleteDialogComponent, data: {} });
export { RenderType_DeleteDialogComponent as RenderType_DeleteDialogComponent };
export function View_DeleteDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "dialog-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "mat-dialog-content", [["class", "content-input mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i3.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 7, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i3.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "mat-raised-button mat-primary mar-25"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "mat-raised-button mat-primary mar-25"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(16, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.data.title)); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.nve; _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("delete-dialog.delete")); _ck(_v, 13, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("delete-dialog.cancel")); _ck(_v, 16, 0, currVal_3); }); }
export function View_DeleteDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delete-dialog", [], null, null, null, View_DeleteDialogComponent_0, RenderType_DeleteDialogComponent)), i1.ɵdid(1, 114688, null, 0, i4.DeleteDialogComponent, [i3.MatDialogRef, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeleteDialogComponentNgFactory = i1.ɵccf("app-delete-dialog", i4.DeleteDialogComponent, View_DeleteDialogComponent_Host_0, {}, {}, []);
export { DeleteDialogComponentNgFactory as DeleteDialogComponentNgFactory };
