import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-final-check-dialog',
  templateUrl: './final-check-dialog.component.html',
  styleUrls: ['./final-check-dialog.component.scss']
})
export class FinalCheckDialogComponent implements OnInit, OnDestroy {

  showAccessoryButton = false;
  constructor(
    private dialogRef: MatDialogRef<FinalCheckDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public purchaseOrder: any) {

  }

  ngOnInit() {
    if (this.purchaseOrder) {
      this.showAccessoryButton = this.purchaseOrder.bdOrderNumber === '' || this.purchaseOrder.bdOrderNumber === null || this.purchaseOrder.bdOrderNumber === undefined;
    }

  }

  proceed(isPacked) {
    this.dialogRef.close(isPacked);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    console.log('FinalCheckDialogComponent');

  }

}
