import {Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import {DATE_FORMATS} from 'src/app/shared/dateFormats';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS},
  ],
})
export class FilterDialogComponent implements OnInit, OnDestroy {
  @ViewChild('tabGroup', { static: false }) tabGroup: ElementRef;
  filterData;
  ordersState;
  user;

  constructor(
    private dialogRef: MatDialogRef<FilterDialogComponent>,
    private renderer: Renderer2,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateAdapter: DateAdapter<Date>) {

  }
  ngOnInit() {
    this.filterData = this.data.filterData;
    this.ordersState = this.data.ordersState;
    console.log("TO SEE WHAT FILTERDATA IS:", this.filterData)
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    if (this.user) {
      this.dateAdapter.setLocale(this.user.languageId);
    }
  }
  closeDialog() {
    setTimeout(() => {
      this.dialogRef.close();

    });
  }
  ngOnDestroy() {
  }
  dateChangeFrom(event) {
    console.log('value', event);
    if (event) {

      this.filterData.dateFrom = event._d;
    } else {
      this.filterData.dateFrom = undefined;
    }
  }
  dateChangeTo(event) {
    if (event) {
      this.filterData.dateTo = event._d;
    } else {
      this.filterData.dateTo = undefined;
    }
  }
  updateStatuses(event, status) {
    if (event.checked) {
      this.filterData.selectedStates.push(status.value);
    } else {
      for (let i = 0; i < this.filterData.selectedStates.length; i++) {
           if (this.filterData.selectedStates[i] === status.value) {
             this.filterData.selectedStates.splice(i, 1);
           }
      }
    }
  }
  isChecked(index) {
    const selectedStates = this.filterData.selectedStates;
    const ordersStates = this.ordersState;
    if (selectedStates.includes(ordersStates[index].value)) {
      return true;
    } else {
      return false;
    }
  }
  passDataToOverview() {
    // from here data for filtering will be sent to overview component in openFilterDialog()
    this.dialogRef.close({dataFromFilterDialog: this.filterData});
  }
}
