<div id="nedID" class="shipping-list" *ngIf="shipingList">
  <div  *ngIf="!shipingList.isShipingList">
  <div class="header-list" >
    <div class="col1">Big Dutchman International GmbH</div>
    <div class="col2">{{ "shipping-list-template.liferavis" | translate }}</div>
    <div class="col3">
      <p>{{ "shipping-list-template.datum" | translate }}</p>
      <p>{{ "shipping-list-template.uhrzeit" | translate }}</p>
      <p>{{ "shipping-list-template.benutzer" | translate }}</p>
    </div>
    <div class="col4 text-right">
      <p>{{ now | date: "dd.MM.yyyy" }}</p>
      <p>{{ now | date: "HH:mm" }}</p>
      <p>{{ shipingList.shipment.packager }}</p>
    </div>
    <hr />
  </div>
  <div class="adress-section">
    <div class="col1">
      <p>{{ "shipping-list-template.advice.no" | translate }}{{ shipingList.shipment.deliveryReceiptNumber }}</p>
      <p>{{ "shipping-list-template.shipping-method" | translate }}{{ shipingList.versandart }}</p>
      <p>{{ "shipping-list-template.supplier-no" | translate }}{{ shipingList.shipment.supplierName }}</p>
    </div>
    <div class="col3 adress">
      <div class="col1">
        <p>{{ "shipping-list-template.lifereadresse" | translate }}</p>
        <p>{{ shipingList.header.name }}</p>
        <p>{{ shipingList.header.street }}</p>
        <p>
          {{ shipingList.header.zipCode }}
          {{ shipingList.header.city }}
        </p>
      </div>
    </div>
  </div>

  <div class="header">
    <div class="col"><p>NVE</p></div>
    <div class="col">
      <p>{{ "shipping-list-template.verpackung" | translate }}</p>
      <p>{{ "shipping-list-template.quantity" | translate }}</p>
    </div>
    <div class="col dimension">
      <p>{{ "shipping-list-template.abmessungen" | translate }}</p>
      <p>{{ "shipping-list-template.Artikelnr BD/Lieferant" | translate }}</p>
    </div>
    <div class="col">
      <p>{{ "shipping-list-template.bruttogewicht" | translate }}</p>
      <p>{{ "shipping-list-template.inhalt" | translate }}</p>
    </div>
    <div class="col"><p>{{ "shipping-list-template.volumen" | translate }}</p></div>
  </div>
  <div class="print-list">
    <div *ngFor="let item of shipingList.data">
      <div class="purchase-order">
        <div class="col">
          <p>{{ "shipping-list-template.bestellung" | translate }}{{ item.purchaseOrder.bdPurchaseOrderNumber }}</p>
        </div>
        <div class="col">
          <p>{{ "shipping-list-template.bd-Auftrag" | translate }}{{ item.purchaseOrder.bdOrderNumber }}</p>
        </div>
        <div class="col">
          <p>{{ "shipping-list-template.ab-Nr" | translate }}{{ item.purchaseOrder.supplierABNumber }}</p>
        </div>
      </div>
      <div *ngFor="let nve of item.nves; let i = index">
        <div class="nve">
          <div class="col">
            <p>{{ nve.nveNumber }}</p>
          </div>
          <div class="col">
            <p>{{ nve.packagingType }}</p>
          </div>
          <div class="col dimension">
            <p>
              {{ nve.lengthNum }} cm / {{ nve.widthNum }} cm / {{ nve.heightNum  }} cm

            </p>
          </div>
          <div class="col">
            <p>{{ nve.weightNum }} kg</p>
          </div>
          <div class="col">
            <p>
              {{
                (nve.widthNum / 100) *
                  (nve.heightNum / 100) *
                  (nve.lengthNum / 100) | number: "1.2-2"
              }}
              m <sup>3</sup>
            </p>
          </div>
        </div>

        <div *ngFor="let item of nve.assignedPurchaseOrderItems">
          <div class="nve">
            <div class="col"></div>
            <div class="col">
              <p>{{ item.quantity }} {{ item.quantityUnit }}</p>
            </div>
            <div class="col dimension">
              <p>
                {{ item.supplierArticleNumber }} / {{ item.bdArticleNumber }}
              </p>
            </div>
            <div class="col dimension">
              <p>{{ item.bdArticleName }} kg</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="nve">
    <div class="col"></div>
    <div class="col text-right"><p>{{ "shipping-list-template.summe" | translate }}</p></div>
    <div class="col dimension">
      <p style="padding-left: 15px;">
        {{ shipingList.data ? shipingList.shipment.nveIds.length : 0 }}
      </p>
    </div>
    <div class="col">
      <p>{{ shipingList.shipment.totalWeight }} kg</p>
    </div>
    <div class="col">
      <p>
        {{ shipingList.shipment.totalVolume | number: "1.2-2" }} m <sup>3</sup>
      </p>
    </div>
  </div>
</div>
  <div  *ngIf="shipingList.isShipingList">
    <div class="header-list">
      <div class="col1">Big Dutchman International GmbH</div>
      <div class="col2">{{ "shipping-list-template.liferavis" | translate }}</div>
      <div class="col3">
        <p>{{ "shipping-list-template.advice.no" | translate }}</p>
        <p>{{ "shipping-list-template.shipping-method" | translate }} </p>
        <p>{{ "shipping-list-template.supplier-no" | translate }}</p>
      </div>
      <div class="col4 text-right">
        <p>{{ shipingList.shipment.deliveryReceiptNumber }}</p>
        <p>&nbsp;{{ shipingList.versandart }}</p>
        <p>{{ shipingList.shipment.supplierName }}</p>

      </div>
      <hr />
    </div>


    <div class="print-list">
      <div class="nve">
        <div class="col">
          <h2><b>{{ "shipping-list-template.summary" | translate }}</b></h2>
        </div>
      </div>
      <div class="nve">
        <div class="col">
          <p>{{ "shipping-list-template.bestellung" | translate }}</p>
        </div>
        <div class="col dimension">
          <p>
            Nummer
          </p>
        </div>
        <div class="col dimension">
          <p>{{ "shipping-list-template.artikelbezeichnung" | translate }}</p>
        </div>
        <div class="col">
          <p>{{ "shipping-list-template.quantity" | translate }}</p>
        </div>
        <div class="col">
          <p>{{ "shipping-list-template.bd-Auftrag" | translate }}</p>
        </div>
      </div>
      <div *ngFor="let item of shipingList.data">
        <div *ngFor="let articleItem of item.articles; let i = index">
            <div class="nve">
              <div class="col">
                <p>{{ item.purchaseOrder.bdPurchaseOrderNumber }}</p>
              </div>
              <div class="col dimension">
                <p>
                  {{ articleItem.bdArticleNumber }}
                </p>
              </div>
              <div class="col dimension">
                <p>{{ articleItem.bdArticleName }} kg</p>
              </div>
              <div class="col">
                <p>{{ articleItem.quantity }} {{ articleItem.quantityUnit }}</p>
              </div>
              <div class="col">
                <p>{{ item.purchaseOrder.bdOrderNumber }}</p>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>



