import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  nve: string;

  constructor(
    private dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.nve = this.data.number;
  }

  close(isDelete) {
    if (isDelete) {
      this.dialogRef.close({proceed : isDelete, nveNumberFromDeleteDialog : this.data.number});
    } else {
      this.dialogRef.close(isDelete);
    }
  }

}
