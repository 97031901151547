<div class="dialog-wrapper">
  <button (click)="close()" class="close-btn" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <div class="text-center">
    <h2>{{'assigne-article-dialog.title' | translate }}</h2>
    <p><strong>{{article.bdArticleName}}</strong></p>
    <p><strong>{{article.bdArticleNumber}}</strong></p>
    <p><strong>{{article.supplierArticleNumber}}</strong></p>
    <p>
      {{'assigne-article-dialog.article-qty' | translate }}
      <strong>{{newOrderedQty}}</strong>
    </p>
     <strong>{{'pack-more-than-order-dialog.question' | translate }}</strong>
    <button
      class="mat-raised-button mat-primary"
      style="margin-top: 10px"
      (click)="confirm()">
      {{'pack-more-than-order-dialog.confirm' | translate }}
    </button>
</div>

