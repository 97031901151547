import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";

@Component({
  selector: 'app-print-status-message-dialog',
  templateUrl: './print-status-message-dialog.component.html',
  styleUrls: ['./print-status-message-dialog.component.scss']
})
export class PrintStatusMessageDialogComponent implements OnInit {
  message: String;

  constructor(
    private dialogForPrintStatusMessage: MatDialogRef<PrintStatusMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Observable<string>
  ) {
  }

  ngOnInit(): void {
    console.log('On ngOnInit data is: ', this.data);
    this.data.subscribe(subscribedMessage => {
      this.message = subscribedMessage
      if (subscribedMessage === 'Printing is done') {
        setTimeout(() => {
          this.dialogForPrintStatusMessage.close()
        }, 1000);
      }
    })
  }

  close(): void {
    this.dialogForPrintStatusMessage.close();
  }

}
