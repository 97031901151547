import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-accessory-unit-dialog',
  templateUrl: './accessory-unit-dialog.component.html',
  styleUrls: ['./accessory-unit-dialog.component.scss']
})
export class AccessoryUnitDialogComponent implements OnInit, OnDestroy {

  article: any;
  constructor(
    private dialogRef: MatDialogRef<AccessoryUnitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    console.log('---------------------------------------------------', this);

    this.article = this.data;

  }

  proceed() {
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    console.log('AccessoryUnitDialogComponent');

  }

}
