import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-print-shipping-dialog',
  templateUrl: './print-shipping-dialog.component.html',
  styleUrls: ['./print-shipping-dialog.component.scss']
})
export class PrintShippingDialogComponent implements OnInit, OnDestroy {


  constructor(
    private dialogRef: MatDialogRef<PrintShippingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {


  }


  printList() {
    console.log('PrintShippingDialogComponentPrintShippingDialogComponent - click');
    console.log('closing the dialog')
    this.dialogRef.close();
  }


  ngOnDestroy(): void {
    console.log('ngOnDestroy  -   PrintShippingDialogComponent');
  }



}
