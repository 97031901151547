<div>
  <button (click)="closeDialog()" class="close-btn" mat-large mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <mat-tab-group #tabGroup class="filter-dialog-mat-tab-group">
    <mat-tab>
      <!--status-->
      <ng-template matTabLabel>
        <span>{{ 'filter-dialog.status' | translate }}</span>
      </ng-template>
      <ul>
        <li *ngFor="let state of this.ordersState; index as i">
          <mat-checkbox [checked]="isChecked(i)"
                        (change)="updateStatuses($event,state)"
          >
            {{ state.text | translate }}
          </mat-checkbox>
        </li>
      </ul>
    </mat-tab>
    <mat-tab>
      <!-- date -->
      <ng-template matTabLabel>
        <span>{{ 'filter-dialog.datum' | translate }}</span>
      </ng-template>
      <div class="date">
        <mat-form-field appearance="outline" class="date" fxFlex="50%">
          <input
            matInput
            [matDatepicker]="picker1"
            placeholder="{{ 'purchase-order-overview.date-from' | translate }}"
            (dateInput)="dateChangeFrom($event.value)"
            [(ngModel)]="filterData.dateFrom"
          />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="date dateTo" fxFlex="50%">
          <input
            matInput
            [matDatepicker]="picker2"
            placeholder="{{ 'purchase-order-overview.date-to' | translate }}"
            (dateInput)="dateChangeTo($event.value)"
            [(ngModel)]="filterData.dateTo"
          />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div>
    <button
      mat-stroked-button
      color="primary"
      class="apply-filters-btn"
      matBadgeColor="accent"
      (click)="passDataToOverview()"
    >
      {{ "filter-dialog.apply-filters" | translate }}
    </button>
  </div>
</div>
