<div>
  <div class="dialog-wrapper scanner">
    <div class="text-center">
      <h2>{{'print-shipping-dialog.advice-sent' | translate}}</h2>
      <h2>{{'print-shipping-dialog.print-shipping-list' | translate}}</h2>
    </div>
    <mat-dialog-actions>
      <button
        class="mat-raised-button mat-primary"
        (click)="printList()"
        style="margin-top: 10px;"
      >
      {{'print-shipping-dialog.print' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
