<div>
  <button (click)="closeDialog()" class="close-btn " mat-large mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <p class="text-center msg">{{ message }}</p>
  <div class="scan-btn-wrapper">
    <button (click)="closeDialog()" class="mat-stroked-button mat-primary scan-btn">
      {{ "shipping-dialog.scan" | translate }}
    </button>
  </div>
</div>
