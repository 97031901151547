import { ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { DATE_FORMATS } from 'src/app/shared/dateFormats';
const ɵ0 = DATE_FORMATS;
export class FilterDialogComponent {
    constructor(dialogRef, renderer, translate, data, dateAdapter) {
        this.dialogRef = dialogRef;
        this.renderer = renderer;
        this.translate = translate;
        this.data = data;
        this.dateAdapter = dateAdapter;
    }
    ngOnInit() {
        this.filterData = this.data.filterData;
        this.ordersState = this.data.ordersState;
        console.log("TO SEE WHAT FILTERDATA IS:", this.filterData);
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        if (this.user) {
            this.dateAdapter.setLocale(this.user.languageId);
        }
    }
    closeDialog() {
        setTimeout(() => {
            this.dialogRef.close();
        });
    }
    ngOnDestroy() {
    }
    dateChangeFrom(event) {
        console.log('value', event);
        if (event) {
            this.filterData.dateFrom = event._d;
        }
        else {
            this.filterData.dateFrom = undefined;
        }
    }
    dateChangeTo(event) {
        if (event) {
            this.filterData.dateTo = event._d;
        }
        else {
            this.filterData.dateTo = undefined;
        }
    }
    updateStatuses(event, status) {
        if (event.checked) {
            this.filterData.selectedStates.push(status.value);
        }
        else {
            for (let i = 0; i < this.filterData.selectedStates.length; i++) {
                if (this.filterData.selectedStates[i] === status.value) {
                    this.filterData.selectedStates.splice(i, 1);
                }
            }
        }
    }
    isChecked(index) {
        const selectedStates = this.filterData.selectedStates;
        const ordersStates = this.ordersState;
        if (selectedStates.includes(ordersStates[index].value)) {
            return true;
        }
        else {
            return false;
        }
    }
    passDataToOverview() {
        // from here data for filtering will be sent to overview component in openFilterDialog()
        this.dialogRef.close({ dataFromFilterDialog: this.filterData });
    }
}
export { ɵ0 };
