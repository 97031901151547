import {Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
  selector: '[appAutoFocus]',
})
export class AutoFocusOnInputDirective implements OnInit {

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    const input: HTMLInputElement = this.el.nativeElement as HTMLInputElement;
    input.focus();
  }
}

