import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-order-purchase-title',
  templateUrl: './order-purchase-title.component.html',
  styleUrls: ['./order-purchase-title.component.scss']
})
export class OrderPurchaseTitlComponent implements OnInit {
  @Input() bdPurchaseOrderNumber;
  @Input() bdOrderNumber;
  @Input() deliveryType;

  constructor() { }

  ngOnInit() {
    console.log('JUST TO SEE ALL DATA: ', 'deliveryType', this.deliveryType, 'bdPurchaseOrderNumber', this.bdPurchaseOrderNumber, 'bdOrderNumber', this.bdOrderNumber);
  }

}
