/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./shared/navi-bar/navi-bar.component.ngfactory";
import * as i4 from "./shared/navi-bar/navi-bar.component";
import * as i5 from "./services/print-label.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/material/dialog";
import * as i8 from "./print/print.component.ngfactory";
import * as i9 from "./print/print.component";
import * as i10 from "./services/print.service";
import * as i11 from "./app.component";
import * as i12 from "@angular/service-worker";
import * as i13 from "./services/login.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "app-main-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-navi-bar", [], null, null, null, i3.View_NaviBarComponent_0, i3.RenderType_NaviBarComponent)), i1.ɵdid(5, 245760, null, 0, i4.NaviBarComponent, [i5.PrintLabelService, i6.TranslateService, i2.Router, i7.MatDialog], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-print", [], null, null, null, i8.View_PrintComponent_0, i8.RenderType_PrintComponent)), i1.ɵdid(7, 114688, null, 0, i9.PrintComponent, [i10.PrintService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i11.AppComponent, [i12.SwUpdate, i10.PrintService, i13.LoginService, i6.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i11.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
