export const environment = {
  production: false,
  licenseenabled: false,
  techuser: 'sda',
  loginUrl: 'https://sda-test.bigdutchman.com/',
  sdaSupplierApiHost: 'https://my-api-test.bigdutchman.com',
  sdaSupplierApiPassword: 'pBTyxZktL7HgmhKY',
  sdaPurcahseOrdersApiHost: 'https://my-api-test.bigdutchman.com',
  sdaPurcahseOrdersApiPassword: 'hjfDskDLq8pucL8F',
  sdaShipmentsApiHost: 'https://my-api-test.bigdutchman.com',
  sdaShipmentsApiPassword: 'S7WT9SNf64wcQq4b',
  dbUrl: 'https://sda:cYPDadSF356Sye9z@sda-test-db.bigdutchman.com/',
  oauth2ClientId: 'id-82923d1d-c358-9f01-f9bb-c384cf823',
  oauth2ClientSecret: 'secret-da33bd22-60a2-d9dc-37d0-48802f2fcfe4',
  authUrl: 'https://my-test.bigdutchman.com/o/oauth2/token',
  url: 'https://my-test.bigdutchman.com/o/feedback/send',
  h2cPath: 'https://my-test.bigdutchman.com/o/com.bigdutchman.feedback.report/js/html2canvas-new.js'
};

