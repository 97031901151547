<div class="dialog-wrapper">
  <button (click)="closeDialog()" class="close-btn" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <div class="text-center" *ngIf="article">
    <h2>{{'accessory-unit-dialog.title' | translate }}</h2>
    <p [innerHTML]="'accessory-unit-dialog.create-prompt' | translate:{bdArticleNumber: article.bdArticleNumber}"></p>
    <mat-dialog-actions>
      <button class="mat-raised-button mat-primary" (click)="proceed()">
        {{'accessory-unit-dialog.proceed' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
