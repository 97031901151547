<div>
  <div class="dialog-wrapper">
    <button (click)="closeDialog()" class="close-btn" mat-large mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
    <div class="text-center">
      <h2>{{ "complete-unit-dialog.title" | translate }}</h2>
      <div class="nve-title">
        <h3 class="text-center">
          {{ "complete-unit-dialog.nve-barcode" | translate }}
        </h3>
        <h3 class="text-center">
          <strong *ngIf="labels">{{ labels[0] }} - {{ labels[labels.length - 1] }}</strong>
          <strong *ngIf="!labels">{{ nve.nveNumber }}</strong>
        </h3>
      </div>
      <p>{{ "complete-unit-dialog.subtitle" | translate }}</p>
      <p>
        <strong>{{ nve.bdPurchaseOrderNumber }}</strong>
      </p>
    </div>
    <mat-dialog-content class="packing">
      <mat-form-field style="width: 90%;">
        <mat-label>
          {{"complete-unit-dialog.packing-type" | translate}}
        </mat-label>

        <input type="text" placeholder="Search" matInput [(ngModel)]="packingTypeDescription"
               (ngModelChange)="doFilter(packingTypeDescription)" [matAutocomplete]="auto" appChangeFocusOnNextEmptyField>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="changeSelect($event)" matNativeControl>
          <mat-option *ngFor="let data of dataArray"
                      [value]="data.id+' '+  ('packaging-types.' + data.id | translate) ">
            {{data.id}}  {{ "packaging-types." + data.id | translate }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="my-form-field" style="width: 90%;">
        <input
          min="0"
          matInput
          type="number"
          placeholder="{{ 'complete-unit-dialog.length' | translate }}"
          [(ngModel)]="nve.lengthNum"
          appChangeFocusOnNextEmptyField
        />
        <span matSuffix>cm</span>
      </mat-form-field>
      <mat-form-field class="my-form-field" style="width: 90%;">
        <input
          min="0"
          matInput
          type="number"
          placeholder="{{ 'complete-unit-dialog.width' | translate }}"
          [(ngModel)]="nve.widthNum"
          appChangeFocusOnNextEmptyField
        />
        <span matSuffix>cm</span>
      </mat-form-field>
      <mat-form-field class="my-form-field" style="width: 90%;">
        <input
          min="0"
          matInput
          type="number"
          placeholder="{{ 'complete-unit-dialog.height' | translate }}"
          [(ngModel)]="nve.heightNum"
          appChangeFocusOnNextEmptyField
        />
        <span matSuffix>cm</span>
      </mat-form-field>
      <mat-form-field class="my-form-field" style="width: 90%;">
        <input
          min="1"
          matInput
          type="number"
          placeholder="{{ 'complete-unit-dialog.weight' | translate }}"
          [(ngModel)]="nve.weightNum"
        />
        <span matSuffix>kg</span>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        [disabled]="
          nve.weightNum === '' ||
          nve.weightNum === null ||
          nve.heightNum === '' ||
          nve.heightNum === null ||
          nve.widthNum === '' ||
          nve.widthNum === null ||
          nve.lengthNum === '' ||
          nve.lengthNum === null ||
          nve.packagingType === null
        "
        class="mat-raised-button mat-primary"
        (click)="printUnitLabel()"
      >
        {{ "complete-unit-dialog.print" | translate }}
      </button>
      <button
        class="mat-raised-button mat-primary"
        (click)="backToPacking()"
        style="margin-top: 10px;"
      >
        <ng-template [ngIf]="labels" [ngIfElse]="notEquallyRanked">
          {{ "complete-unit-dialog.cancelEquallyRankedUnits" | translate }}
        </ng-template>
        <ng-template #notEquallyRanked>
          {{ "complete-unit-dialog.back" | translate }}
        </ng-template>
      </button>
    </mat-dialog-actions>
  </div>
</div>
